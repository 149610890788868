import { PiJarLabelFill } from "react-icons/pi";
import { TbPaperBag, TbVaccineBottle } from "react-icons/tb";

export const productFamilyOptions = [
	{ value: "indica", label: "אינדיקה" },
	{ value: "hybrid", label: "היבריד" },
	{ value: "sativa", label: "סאטיבה" },
];

export const productTypeOptions = [
	{ value: "flower", label: "תפרחת" },
	{ value: "oil", label: "שמן" },
	{ value: "preroll", label: "גליליות" },
];

export const productTypeOptionsForPrescriptions = [
	{ value: "flower", label: "תפרחת/גלילות" },
	{ value: "oil", label: "שמן" },
];

export const productCategoryOptions = [
	{ value: "T22/C4", label: "T22/C4" },
	{ value: "T20/C4", label: "T20/C4" },
	{ value: "T18/C3", label: "T18/C3" },
	{ value: "T15/C3", label: "T15/C3" },
	{ value: "T12/C2", label: "T12/C2" },
	{ value: "T10/C2", label: "T10/C2" },
	{ value: "T12/C12", label: "T12/C12" },
	{ value: "T10/C10", label: "T10/C10" },
	{ value: "T8/C8", label: "T8/C8" },
	{ value: "T5/C5", label: "T5/C5" },
	{ value: "T3/C3", label: "T3/C3" },
	{ value: "T1/C1", label: "T1/C1" },
	{ value: "T0/C30", label: "T0/C30" },
	{ value: "T1/C28", label: "T1/C28" },
	{ value: "T0/C26", label: "T0/C26" },
	{ value: "T1/C24", label: "T1/C24" },
	{ value: "T0/C24", label: "T0/C24" },
	{ value: "T1/C22", label: "T1/C22" },
	{ value: "T1/C20", label: "T1/C20" },
	{ value: "T3/C18", label: "T3/C18" },
	{ value: "T3/C15", label: "T3/C15" },
	{ value: "T3/C12", label: "T3/C12" },
	{ value: "T5/C10", label: "T5/C10" },
];

export const thcPotent = [
	"T22/C4",
	"T20/C4",
	"T18/C3",
	"T15/C3",
	"T12/C2",
	"T10/C2",
];

export const categoriesPercents = {
	"T22/C4": { thc: "19.9%-24.2%", cbd: "0%-4%" },
	"T20/C4": { thc: "15.5%-24.4%", cbd: "0%-7.5%" },
	"T18/C3": { thc: "16.6%-19.8%", cbd: "0%-3%" },
	"T15/C3": { thc: "10.5%-19.5%", cbd: "0%-5.5%" },
	"T12/C2": { thc: "12.1%-14.4%", cbd: "0%-2%" },
	"T10/C2": { thc: "5.5%-14.5%", cbd: "0%-3.8%" },
	"T12/C12": { thc: "12.1%-14.4%", cbd: "12.1%-14.4%" },
	"T10/C10": { thc: "5.5%-14.5%", cbd: "5.5%-14.5%" },
	"T8/C8": { thc: "6.4%-9.6%", cbd: "6.4%-9.6%" },
	"T5/C5": { thc: "0.5%-9.5%", cbd: "0.5%-9.5%" },
	"T3/C3": { thc: "0%-6.5%", cbd: "0%-6.5%" },
	"T1/C1": { thc: "0.8%-1.2%", cbd: "0.8%-1.2%" },
	"T0/C30": { thc: "0%-0.5%", cbd: "30.9%-33%" },
	"T1/C28": { thc: "0%-2.5%", cbd: "24%-32.5%" },
	"T0/C26": { thc: "0%-0.5%", cbd: "24.3%-28.6%" },
	"T1/C24": { thc: "0%-1.5%", cbd: "22.1%-26.4%" },
	"T0/C24": { thc: "0%-0.5%", cbd: "20%-28%" },
	"T1/C22": { thc: "0%-1.5%", cbd: "19.4%-24.2%" },
	"T1/C20": { thc: "0%-2.5%", cbd: "15.5%-24.4%" },
	"T3/C18": { thc: "0%-3%", cbd: "16.6%-19.8%" },
	"T3/C15": { thc: "0.4%-5.5%", cbd: "10.5%-19.5%" },
	"T3/C12": { thc: "0%-3%", cbd: "12.1%-14.4%" },
	"T5/C10": { thc: "2.5%-7.5%", cbd: "5.5%-14.5%" },
};

export const categoriesToShowFixedRange = [
	"T22/C4",
	"T18/C3",
	"T12/C2",
	"T12/C12",
	"T8/C8",
	"T1/C1",
	"T0/C26",
	"T1/C22",
	"T3/C18",
	"T3/C12",
];

export const cbdPotent = [
	"T0/C30",
	"T1/C28",
	"T0/C26",
	"T1/C24",
	"T0/C24",
	"T1/C22",
	"T1/C20",
	"T3/C18",
	"T3/C15",
	"T3/C12",
	"T5/C10",
];

export const listProductTypes = [
	{
		label: "oil",
		value: "שמן",
		icon: "/Drop.svg",
		longLabel: "שמן",
		backgroundColor: "#ffecb3",
		textColor: "#bcae86",
		hoverBackgroundColor: "#ffd966",
		hoverTextColor: "#785d0c",
		activeBgColor: "#ffd966",
		activeTextColor: "#785d0c",
		activeBorderColor: "#ccae52",
		paddingRight: "7px",
		type: "product_type",
	},
	{
		label: "flower",
		value: "תפרחת",
		icon: "/Flower.svg",
		longLabel: "תפרחת",
		hoverBackgroundColor: "#bff0ad",
		textColor: "#a6b2a2",
		backgroundColor: "#dff8d6",
		hoverTextColor: "#4c6444",
		activeBgColor: "#bff0ad",
		activeTextColor: "#4c6444",
		activeBorderColor: "#99c08a",
		paddingRight: "1px",
		type: "product_type",
	},
	{
		label: "preroll",
		value: "גליליות",
		icon: "/Frame 1.svg",
		longLabel: "גליליות",
		backgroundColor: "#fae6e6",
		textColor: "#aca3a3",
		hoverBackgroundColor: "#f4cccc",
		hoverTextColor: "#7f6868",
		activeBgColor: "#f4cccc",
		activeTextColor: "#7f6868",
		activeBorderColor: "#c3a3a3",
		paddingRight: "3px",
		type: "product_type",
	},
	{
		label: "T22/C4",
		value: "T22 C4",
		longLabel: "T22/C4",
		backgroundColor: "#BA5D5D",
		textColor: "#fff",
		hoverBackgroundColor: "#9C1818",
		hoverTextColor: "#fff",
		activeBgColor: "#9C1818",
		activeTextColor: "#fff",
		activeBorderColor: "#790505",
		paddingRight: "3px",
		type: "category",
	},
	{
		label: "T20/C4",
		value: "T20 C4",
		longLabel: "T20/C4",
		backgroundColor: "#CB756E",
		textColor: "#fff",
		hoverBackgroundColor: "#B53A30",
		hoverTextColor: "#fff",
		activeBgColor: "#B53A30",
		activeTextColor: "#fff",
		activeBorderColor: "#790505",
		paddingRight: "3px",
		type: "category",
	},
	{
		label: "T18/C3",
		value: "T18 C3",
		longLabel: "T18/C3",
		backgroundColor: "#DD8D7F",
		textColor: "#fff",
		hoverBackgroundColor: "#CE5C48",
		hoverTextColor: "#fff",
		activeBgColor: "#CE5C48",
		activeTextColor: "#fff",
		activeBorderColor: "#8D1E14",
		paddingRight: "3px",
		type: "category",
	},
	{
		label: "T15/C3",
		value: "T15 C3",
		longLabel: "T15/C3",
		backgroundColor: "#DD8D7F",
		textColor: "#fff",
		hoverBackgroundColor: "#E67E5F",
		hoverTextColor: "#fff",
		activeBgColor: "#E67E5F",
		activeTextColor: "#fff",
		activeBorderColor: "#A13823",
		paddingRight: "3px",
		type: "category",
	},
	{
		label: "T12/C2",
		value: "T12 C2",
		longLabel: "T12/C2",
		backgroundColor: "#EEA58F",
		textColor: "#fff",
		hoverBackgroundColor: "#E67E5F",
		hoverTextColor: "#fff",
		activeBgColor: "#E67E5F",
		activeTextColor: "#fff",
		activeBorderColor: "#B55132",
		paddingRight: "3px",
		type: "category",
	},
	{
		label: "T10/C2",
		value: "T10 C2",
		longLabel: "T10/C2",
		backgroundColor: "#FFBCA0",
		textColor: "#fff",
		hoverBackgroundColor: "#FFA077",
		hoverTextColor: "#fff",
		activeBgColor: "#FFA077",
		activeTextColor: "#fff",
		activeBorderColor: "#C96A41",
		paddingRight: "3px",
		type: "category",
	},
	{
		label: "T12/C12",
		value: "T12 C12",
		longLabel: "T12/C12",
		backgroundColor: "#7E7C9C",
		textColor: "#fff",
		hoverBackgroundColor: "#474471",
		hoverTextColor: "#fff",
		activeBgColor: "#474471",
		activeTextColor: "#fff",
		activeBorderColor: "#2E2A5F",
		paddingRight: "3px",
		type: "category",
	},
	{
		label: "T10/C10",
		value: "T10 C10",
		longLabel: "T10/C10",
		backgroundColor: "#8B7EA5",
		textColor: "#fff",
		hoverBackgroundColor: "#5A467F",
		hoverTextColor: "#fff",
		activeBgColor: "#5A467F",
		activeTextColor: "#fff",
		activeBorderColor: "#3D2C67",
		paddingRight: "3px",
		type: "category",
	},
	{
		label: "T8/C8",
		value: "T8 C8",
		longLabel: "T8/C8",
		backgroundColor: "#997FAF",
		textColor: "#fff",
		hoverBackgroundColor: "#6E488D",
		hoverTextColor: "#fff",
		activeBgColor: "#6E488D",
		activeTextColor: "#fff",
		activeBorderColor: "#4C2E6E",
		paddingRight: "3px",
		type: "category",
	},
	{
		label: "T5/C5",
		value: "T5 C5",
		longLabel: "T5/C5",
		backgroundColor: "#A780B9",
		textColor: "#fff",
		hoverBackgroundColor: "#814A9B",
		hoverTextColor: "#fff",
		activeBgColor: "#814A9B",
		activeTextColor: "#fff",
		activeBorderColor: "#5C3076",
		paddingRight: "3px",
		type: "category",
	},
	{
		label: "T3/C3",
		value: "T3 C3",
		longLabel: "T3/C3",
		backgroundColor: "#B582C3",
		textColor: "#fff",
		hoverBackgroundColor: "#954CA9",
		hoverTextColor: "#fff",
		activeBgColor: "#954CA9",
		activeTextColor: "#fff",
		activeBorderColor: "#6B327D",
		paddingRight: "3px",
		type: "category",
	},
	{
		label: "T1/C1",
		value: "T1 C1",
		longLabel: "T1/C1",
		backgroundColor: "#BD8BC5",
		textColor: "#fff",
		hoverBackgroundColor: "#A15AAC",
		hoverTextColor: "#fff",
		activeBgColor: "#A15AAC",
		activeTextColor: "#fff",
		activeBorderColor: "#7A3485",
		paddingRight: "3px",
		type: "category",
	},
	{
		label: "T0/C30",
		value: "T0 C30",
		longLabel: "T0/C30",
		backgroundColor: "#738A6C",
		textColor: "#fff",
		hoverBackgroundColor: "#37582D",
		hoverTextColor: "#fff",
		activeBgColor: "#37582D",
		activeTextColor: "#fff",
		activeBorderColor: "#24441B",
		paddingRight: "3px",
		type: "category",
	},
	{
		label: "T1/C28",
		value: "T1 C28",
		longLabel: "T1/C28",
		backgroundColor: "#749176",
		textColor: "#fff",
		hoverBackgroundColor: "#39623B",
		hoverTextColor: "#fff",
		activeBgColor: "#39623B",
		activeTextColor: "#fff",
		activeBorderColor: "#244B26",
		paddingRight: "3px",
		type: "category",
	},
	{
		label: "T0/C26",
		value: "T0 C26",
		longLabel: "T0/C26",
		backgroundColor: "#779780",
		textColor: "#fff",
		hoverBackgroundColor: "#3C6B49",
		hoverTextColor: "#fff",
		activeBgColor: "#3C6B49",
		activeTextColor: "#fff",
		activeBorderColor: "#245131",
		paddingRight: "3px",
		type: "category",
	},
	{
		label: "T1/C24",
		value: "T1 C24",
		longLabel: "T1/C24",
		backgroundColor: "#789E89",
		textColor: "#fff",
		hoverBackgroundColor: "#3E7556",
		hoverTextColor: "#fff",
		activeBgColor: "#3E7556",
		activeTextColor: "#fff",
		activeBorderColor: "#24583B",
		paddingRight: "3px",
		type: "category",
	},
	{
		label: "T0/C24",
		value: "T0 C24",
		longLabel: "T0/C24",
		backgroundColor: "#79A593",
		textColor: "#fff",
		hoverBackgroundColor: "#407E64",
		hoverTextColor: "#fff",
		activeBgColor: "#407E64",
		activeTextColor: "#fff",
		activeBorderColor: "#245F46",
		paddingRight: "3px",
		type: "category",
	},
	{
		label: "T1/C22",
		value: "T1 C22",
		longLabel: "T1/C22",
		backgroundColor: "#7BAC9C",
		textColor: "#fff",
		hoverBackgroundColor: "#438872",
		hoverTextColor: "#fff",
		activeBgColor: "#438872",
		activeTextColor: "#fff",
		activeBorderColor: "#256651",
		paddingRight: "3px",
		type: "category",
	},
	{
		label: "T1/C20",
		value: "T1 C20",
		longLabel: "T1/C20",
		backgroundColor: "#7DB2A6",
		textColor: "#fff",
		hoverBackgroundColor: "#459180",
		hoverTextColor: "#fff",
		activeBgColor: "#459180",
		activeTextColor: "#fff",
		activeBorderColor: "#256C5C",
		paddingRight: "3px",
		type: "category",
	},
	{
		label: "T3/C18",
		value: "T3 C18",
		longLabel: "T3/C18",
		backgroundColor: "#7EB9B0",
		textColor: "#fff",
		hoverBackgroundColor: "#479B8E",
		hoverTextColor: "#fff",
		activeBgColor: "#479B8E",
		activeTextColor: "#fff",
		activeBorderColor: "#257367",
		paddingRight: "3px",
		type: "category",
	},
	{
		label: "T3/C15",
		value: "T3 C15",
		longLabel: "T3/C15",
		backgroundColor: "#80BFB9",
		textColor: "#fff",
		hoverBackgroundColor: "#49A49B",
		hoverTextColor: "#fff",
		activeBgColor: "#49A49B",
		activeTextColor: "#fff",
		activeBorderColor: "#257A71",
		paddingRight: "3px",
		type: "category",
	},
	{
		label: "T3/C12",
		value: "T3 C12",
		longLabel: "T3/C12",
		backgroundColor: "#82C6C3",
		textColor: "#fff",
		hoverBackgroundColor: "#4CAEA9",
		hoverTextColor: "#fff",
		activeBgColor: "#4CAEA9",
		activeTextColor: "#fff",
		activeBorderColor: "#25807C",
		paddingRight: "3px",
		type: "category",
	},
	{
		label: "T5/C10",
		value: "T5 C10",
		longLabel: "T5/C10",
		backgroundColor: "#83CCCC",
		textColor: "#fff",
		hoverBackgroundColor: "#4EB7B7",
		hoverTextColor: "#fff",
		activeBgColor: "#4EB7B7",
		activeTextColor: "#fff",
		activeBorderColor: "#258787",
		paddingRight: "3px",
		type: "category",
	},
	{
		label: "indica",
		textColor: "#fff",
		activeTextColor: "#fff",
		hoverTextColor: "#fff",
		value: "אינ'",
		longLabel: "אינדיקה",
		backgroundColor: "#7f99ac",
		hoverBackgroundColor: "#003459",
		activeBgColor: "#003459",
		activeBorderColor: "#1e293b",
		type: "family",
	},
	{
		label: "hybrid",
		textColor: "#fff",
		activeTextColor: "#fff",
		hoverTextColor: "#fff",
		value: "היב'",
		longLabel: "היבריד",
		backgroundColor: "#7fbed3",
		hoverBackgroundColor: "#007ea7",
		activeBgColor: "#007ea7",
		activeBorderColor: "#0a6588",
		type: "family",
	},
	{
		label: "sativa",
		textColor: "#fff",
		activeTextColor: "#fff",
		hoverTextColor: "#fff",
		value: "סאט'",
		longLabel: "סאטיבה",
		backgroundColor: "#7fd3f3",
		hoverBackgroundColor: "#00a8e8",
		activeBgColor: "#00a8e8",
		activeBorderColor: "#0891c5",
		type: "family",
	},
];

export const familyList = [
	{
		id: "indica",
		value: "אינדיקה",
		label: "אינ'",
		longLabel: "אינדיקה",
		backgroundColor: "#003459",
		hoverBackgroundColor: "#003459",
	},
	{
		id: "sativa",
		value: "סאטיבה",
		label: "סאט'",
		longLabel: "סאטיבה",
		backgroundColor: "#00a8e8",
		hoverBackgroundColor: "#00a8e8",
	},
	{
		id: "hybrid",
		value: "היבריד",
		label: "היב'",
		longLabel: "היבריד",
		backgroundColor: "#007ea7",
		hoverBackgroundColor: "#007ea7",
	},
];

export const WBO = {
	"2xs": 235,
	xs: 475,
	sm: 640,
	md: 768,
	mdlg: 896,
	lg: 1024,
	xl: 1280,
	"2xl": 1536,
	"3xl": 1920,
};

export const opt = [
	{ value: "", label: "בחר" },
	{ value: "02", label: "02" },
	{ value: "03", label: "03" },
	{ value: "04", label: "04" },
	{ value: "08", label: "08" },
	{ value: "09", label: "09" },
	{ value: "050", label: "050" },
	{ value: "051", label: "051" },
	{ value: "052", label: "052" },
	{ value: "053", label: "053" },
	{ value: "054", label: "054" },
	{ value: "055", label: "055" },
	{ value: "056", label: "056" },
	{ value: "058", label: "058" },
	{ value: "071", label: "071" },
	{ value: "072", label: "072" },
	{ value: "073", label: "073" },
	{ value: "074", label: "074" },
	{ value: "076", label: "076" },
	{ value: "077", label: "077" },
	{ value: "079", label: "079" },
];

export const optOnlyMobile = [
	{ value: "", label: "בחר" },
	{ value: "050", label: "050" },
	{ value: "051", label: "051" },
	{ value: "052", label: "052" },
	{ value: "053", label: "053" },
	{ value: "054", label: "054" },
	{ value: "055", label: "055" },
	{ value: "056", label: "056" },
	{ value: "058", label: "058" },
];

export const growTypeOptions = [
	{ value: "indoor", label: "פנים אינדור" },
	{ value: "mixed", label: "פנים משולב " },
	{ value: "greenhouse", label: " חממה" },
];

export const priceRangeOptions = [
	{ value: [0, 100], label: "₪0-100" },
	{ value: [101, 200], label: "₪100-200" },
	{ value: [201, 300], label: "₪200-300" },
	{ value: [301, 400], label: "₪300-400" },
	{ value: [401, 999], label: "מעל ₪400" },
];

export const TAB = {
	BY_PRODUCT: "byProduct",
	BY_STORE: "byStore",
	BY_FLOWER: "byFlower",
	BY_OIL: "byOil",
	BY_PRE_ROLL: "byPreroll",
	BY_CITY: "byCity",
	BY_PRICE: "byPrice",
	BY_THC: "byThc",
	BY_CBD: "byCbd",
	BY_MARKETER: "byMarketer",
	BY_MANUFACTURER: "byManufacturer",
	BY_ON_SALE: "byOnSale",
	BY_DISCOUNT: "byDiscount",
	BY_CREATED_DATE: "byCreatedDate",
};
export const tabs = [
	{ value: TAB.BY_PRODUCT, label: <div>מוצרים</div> },
	{ value: TAB.BY_STORE, label: <div>בתי מרקחת</div> },
	{ value: TAB.BY_CITY, label: <div>ערים</div> },
	{ value: TAB.BY_PRICE, label: <div>מחיר</div> },
	{ value: TAB.BY_CREATED_DATE, label: <div>חדשים</div> },
	{ value: TAB.BY_THC, label: <div>THC</div> },
	{ value: TAB.BY_CBD, label: <div>CBD</div> },
	{ value: TAB.BY_MARKETER, label: <div>משווקים</div> },
	{ value: TAB.BY_MANUFACTURER, label: <div>מגדלים</div> },
	{ value: TAB.BY_ON_SALE, label: <div>מבצעים</div> },
	{ value: TAB.BY_DISCOUNT, label: <div>הנחות</div> },
];
export const priceRanges = [
	{ id: 1, label: "₪100 - ₪0" }, //
	{ id: 2, label: "₪200 - ₪100" }, //
	{ id: 3, label: "₪300 - ₪200" }, //
	{ id: 4, label: "₪400 - ₪300" }, //
	{ id: 5, label: "+₪400" }, //
];

export const myAccountFormFields = [
	"first_name",
	"last_name",
	"id_url",
	"id_number",
	"authorized_person_active",
	"authorized_person_first_name",
	"authorized_person_last_name",
	"authorized_person_phone_number",
	"authorized_person_id_number",
	"authorized_second_person_active",
	"authorized_second_person_first_name",
	"authorized_second_person_last_name",
	"authorized_second_person_phone_number",
	"authorized_second_person_id_number",
	"phone_number",
	"email",
	"signature_url",
	"medical_condition",
	"is_medical_condition",
];

export const statusLabelAndColor = {
	Created: { label: "התקבלה", color: "text-[#f0ad4e]" },
	Processing: { label: "בטיפול", color: "text-[#5bc0de]" },
	Delivered: { label: "לאיסוף", color: "text-[#0275d8]" },
	Completed: { label: "נמסרה", color: "text-[#5cb85c]" },
	Cancelled: { label: "בוטלה", color: "text-red-400" },
};
export const svgIconByType = {
	flower: "/grid/Flower.svg",
	oil: "/grid/Oil.svg",
	preroll: "/grid/Roll.svg",
};

export const colorByType = {
	flower: "border-[#BFF0AD]/20 bg-[#BFF0AD]/10",
	oil: "border-[#FFD966]/20 bg-[#FFD966]/10",
	preroll: "border-[#F4CCCC]/20 bg-[#F4CCCC]/10",
};

export const hebCategoryName = {
	indica: "אינדיקה",
	sativa: "סאטיבה",
	hybrid: "היבריד",
};

export const getColorByType = product_type => {
	if (product_type === "flower") {
		return "border-[#BFF0AD]/20 bg-[#BFF0AD]/10";
	} else if (product_type === "oil") {
		return "border-[#FFD966]/20 bg-[#FFD966]/10";
	} else if (product_type === "preroll") {
		return "border-[#F4CCCC]/20 bg-[#F4CCCC]/10";
	}
	return "";
};

export const getHebCategoryName = cat => {
	if (cat === "indica") {
		return "אינדיקה";
	} else if (cat === "sativa") {
		return "סאטיבה";
	} else if (cat === "hybrid") {
		return "היבריד";
	}
	return "";
};

export const userDocTypes = {
	user_license: "user_license",
	user_id: "user_id",
	user_mirsham: "user_mirsham",
};

export const LOGIN_STEPS = {
	LOGIN: "login",
	CODE_LOGIN: "codeLogin",
	CODE_VERIFY: "codeVerify",
	NOT_FOUND: "notFound",
	REGISTER: "register",
	WELCOME: "welcome",
};

export const CHECKOUT_STEPS = {
	USER_INFO: "userInfo",
	LICENSE: "license",
	PRESCRIPTION: "prescription",
};

export const ARRIVE_AT_OPTIONS = [
	{ value: "12:00", label: "לפני 12:00" },
	{ value: "16:00", label: "12:00 - 16:00" },
	{ value: "23:00", label: "אחרי 16:00" },
];

export const DELIVERY_METHODS = {
	PICKUP: "pickup",
	DELIVERY: "delivery",
};

export const STATUS_CANCELLED_REASONS = [
	{ value: "Not enough stock", label: "⁠כמות מוצרים חסרה (מלאי מוגבל)" },
	{ value: "Products out of stock", label: "⁠מוצרים אזלו מהמלאי" },
	{ value: "Patient rejected", label: "מטופל/ת התחרט/ה" },
	{ value: "Patient not available", label: "⁠מטופל/ת לא זמינ/ה" },
	{ value: "Patient did not come to pick up", label: "מטופל לא הגיע לאסוף" },
	{
		value: "Courier was unable to coordinate with patient",
		label: "שליח לא הצליח לתאם עם המטופל/ת",
	},
	{ value: "Prescription used/unopened", label: "⁠מרשם נוצל/לא נפתח" },
	{ value: "Missing/incorrect documents", label: "⁠מסמכים חסרים/לא תקינים" },
	{ value: "Other", label: "אחר" },
];

export const selectStyles = {
	control: (base, state) => {
		return {
			...base,
			transition: "all .2s ease",
			minHeight: 20,
			paddingLeft: 0,
			paddingRight: 0,
			cursor: "pointer",
			border: state.menuIsOpen
				? "solid 1px #64748b"
				: "solid 1px rgb(209, 213, 219)",
			boxShadow: state.menuIsOpen
				? "0 0 0 1px #64748b"
				: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
			backgroundColor: "#fff",

			"&:hover": {
				border: state.menuIsOpen
					? "solid 1px #64748b"
					: "solid 1px rgb(209, 213, 219)",
			},
			"*": {
				boxShadow: "none !important",
			},
		};
	},
	multiValue: provided => ({
		...provided,
		borderRadius: "8px",
		margin: "3px 5px",
		padding: "0px 2px",
	}),
	input: provided => ({
		...provided,
		margin: "0px",
		outline: "none",
	}),
	indicatorsContainer: provided => ({
		...provided,
		height: "auto",
		paddingTop: 0,
		paddingBottom: 0,
		display: "flex",
		borderRight: "none",
	}),
	indicatorSeparator: provided => ({
		display: "none",
	}),
	dropdownIndicator: provided => ({
		...provided,
		paddingTop: 0,
		paddingBottom: 0,
		paddingLeft: 4,
		paddingRight: 4,
		transform: "scale(0.75)",
	}),
	menu: provided => ({
		...provided,
		minWidth: 80,
	}),
	option: (base, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...base,
			cursor: "pointer",
			height: "auto",
			minHeight: 0,
			padding: "6px 12px",
			fontWeight: 400,
			background: isDisabled
				? "#fff"
				: isFocused
				? "rgba(0,0,0,0.025)"
				: "none",
			color: isDisabled ? "#D1D5DB" : isFocused ? "#1F2937" : "#374151",
			"&:active": {
				background: isDisabled
					? "#fff"
					: isFocused
					? "rgba(0,0,0,0.025)"
					: "none",
				color: isDisabled ? "#D1D5DB" : isFocused ? "#1F2937" : "#374151",
			},
		};
	},
};

export const PRODUCT_PACKAGE_HEB_TRANSLATION = {
	jar: { text: "צנצנת", icon: <PiJarLabelFill className="h-5 w-5" /> },
	bottle: { text: "בקבוקון", icon: <TbVaccineBottle className="h-5 w-5" /> },
	bag: { text: "שקית", icon: <TbPaperBag className="h-5 w-5" /> },
};
