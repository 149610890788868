import { createContext, useEffect, useRef, useState } from "react";
import { useApi } from "components/hooks/useApi";
import { useRouter } from "next/router";
import useLocalStorage from "components/hooks/useLocalStorage";

const initialState = {
	authenticated: false,
	claims: null,
};

const AuthContext = createContext(initialState);

const AuthProvider = ({ children }) => {
	const api = useApi();
	const { clearLocalStorage } = useLocalStorage(`j-user`);
	const [ready, setReady] = useState(false);
	const [claims, setClaims] = useState(null);
	const [lastPath, setLastPath] = useState("/");
	const router = useRouter();
	const fetching = useRef(false);
	useEffect(() => {
		if (!router.asPath.includes("code=") && !router.asPath.includes("login")) {
			setLastPath(router.asPath);
			localStorage.setItem("lastPath", router.asPath);
		}
	}, [router.asPath]);

	useEffect(() => void checkLogin(), []);

	const checkLogin = async () => {
		if (!!fetching.current) {
			return;
		}
		fetching.current = true;
		const res = await api.checkLogin();
		fetching.current = false;
		if (res.ok) {
			const isAuthenticated = !!res.data.authenticated;
			if (isAuthenticated) {
				setClaims(res.data.claims);
			} else {
				setClaims(null);
			}
		} else {
			setClaims(null);
			console.log("check login error", res.error);
		}
		setReady(true);
	};

	const logout = async (redirect = "/") => {
		await api.submitLogout();
		clearLocalStorage();
		window.location.href = redirect;
		return checkLogin();
	};

	const authenticated = claims !== null;
	return (
		<AuthContext.Provider
			value={{
				authenticated,
				claims,
				ready,
				checkLogin,
				logout,
				lastPath,
			}}
		>
			{!!ready ? (
				children
			) : (
				<div className="mx-auto text-center py-24 text-gray-500">טוען...</div>
			)}
		</AuthContext.Provider>
	);
};

export { AuthContext, AuthProvider };
