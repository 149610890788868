import { hasCharactersAfterStringInURL } from "util/common";
import { clickEvents } from "./constants";

const ID_PREFIX_SEPARATOR = "__";

export const handleClickEvent = (e, collectAnalytics) => {
	// id of the element should be __store_tab_click__sales for example => sales is the value (sales tab was clicked)
	let elementId = findParentWithId(e.target, ID_PREFIX_SEPARATOR);
	if (!elementId) {
		return;
	}
	const eventId = elementId.slice(2);
	const splittedEventId = eventId.split(ID_PREFIX_SEPARATOR);
	const eventName = splittedEventId[0];
	const eventPayload = {};
	if (splittedEventId.length > 1) {
		// event ID has a value associate with
		const eventValue = splittedEventId[1];
		const eventData = clickEvents[eventName];
		if (eventData) {
			eventPayload[eventData.field] = eventValue;
		}
	}
	if (!eventName) {
		return;
	}
	collectAnalytics(eventName, eventPayload);
};

const findParentWithId = (
	clickedElementTarget,
	desiredIdPrefix,
	maxDepth = 4,
) => {
	// pass maxDepth -1 for entire DOM search
	let node = clickedElementTarget;
	let counter = maxDepth;
	while (node) {
		if (counter === 0) {
			return;
		}
		if (node.id.startsWith(desiredIdPrefix)) {
			return node.id;
		}
		node = node.parentElement;
		counter -= 1;
	}
};

export const getEventScopeFromUrl = (path, row, widgetActiveTab = null) => {
	const storesScope = ["/stores/sales/", "/stores/discounts/", "/stores/"];
	const isSingleProductPage =
		path.startsWith("/products/") && path !== "/products/";
	const isSingleProductPageWidget = path.startsWith("/widget/stores/product/");
	if (
		path === "/widget/home/" &&
		(widgetActiveTab === "byStore" || widgetActiveTab === "byDiscounts")
	) {
		return "stores";
	}
	if (
		storesScope.includes(path) ||
		isSingleProductPage ||
		isSingleProductPageWidget
	) {
		return "stores";
	} else {
		return "products";
	}
};

export const getPayloadFromExpandedRow = (
	row,
	path,
	widgetActiveTab = null,
) => {
	const eventScope = getEventScopeFromUrl(path, row, widgetActiveTab);
	let eventPayload;
	let eventName;
	switch (eventScope) {
		case "products":
			// product row expanded
			eventName = "product_row_expanded";
			eventPayload = {
				product_id: row.id,
				...(row.store_id && {
					store_id: row.store_id,
					display_name: row?.display_name,
				}),
			};
			break;
		case "stores":
			eventName = "pharmacy_row_expanded";
			eventPayload = {
				store_id: row.id,
				display_name: row.display_name,
			};
			break;
	}
	return { eventName, eventPayload };
};
