import { getCookie } from "util/common";
import { apiServerUrl } from "jane.config";

export function useHttp() {
	const $get = async url => {
		return await sendHttpRequest(url, {}, unauthorizedCallback);
	};

	const $post = async (url, params = {}) => {
		const opts = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				"X-CSRFToken": getCsrfToken(),
			},
			body: JSON.stringify(params),
		};
		return await sendHttpRequest(url, opts, unauthorizedCallback);
	};

	const $upload = async (file, storageName, documentType = null) => {
		if (!file || !storageName) {
			throw new Error(`$upload: missing arguments.`);
		}
		const fd = new FormData();
		fd.append("file", file);
		fd.append("document_type", documentType);
		const url = `${apiServerUrl}/api/upload/${storageName}/`;
		const opts = {
			method: "POST",
			headers: { "X-CSRFToken": getCsrfToken() },
			body: fd,
		};
		return await sendHttpRequest(url, opts, unauthorizedCallback);
	};

	const unauthorizedCallback = async () => {
		window.location.href = "/logout/";
	};

	return {
		$get,
		$post,
		$upload,
	};
}

async function sendHttpRequest(
	url,
	opts = {},
	unauthorizedCallback = () => {},
) {
	opts = { ...opts, credentials: "include" };
	let res;
	try {
		res = await fetch(url, opts);
	} catch (err) {
		return {
			ok: false,
			error: err,
		};
	}
	if (!!res.ok) {
		return normalizeResponse(res);
	}

	if (res.status === 401) {
		unauthorizedCallback();
	}

	const title = `שגיאה ${res.status || ""}`;
	const message = res.statusText || "שגיאה לא מוכרת.";
	return {
		ok: false,
		error: { title, message },
	};
}

async function normalizeResponse(resp) {
	let respJson = {};
	try {
		respJson = await resp.json();
	} catch (err) {
		return {
			ok: false,
			resp,
		};
	}

	if (respJson?.status === "OK") {
		return { ok: true, data: respJson.data || {} };
	} else {
		return { ok: false, error: respJson.error || {} };
	}
}

const getCsrfToken = () => getCookie("csrftoken");
