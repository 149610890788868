import IdField from "components/fields/IdField";
import { Button } from "components/form/Button";
import React from "react";
import { FaRegIdCard } from "react-icons/fa";
import GoogleButton from "./GoogleButton";
import { InformationCircleIcon } from "@heroicons/react/outline";

function IdStep({
	errorMessage,
	setErrorMessage,
	idLogin,
	loading = false,
	loginForm,
	onLogin,
}) {
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors, isValid, isDirty, isSubmitting },
	} = loginForm;

	return (
		<div className="rounded-md bg-white flex flex-col gap-4 p-6 md:px-24 md:py-14">
			{!!errorMessage && (
				<div className="border p-2 rounded-md flex items-center gap-2 text-red-900 fond-semibold border-red-400 bg-red-100">
					<InformationCircleIcon className="h-4 w-4" />
					{errorMessage}
				</div>
			)}
			<div className="flex justify-between items-center w-full text-jane-500">
				<span className="flex flex-col gap-1">
					<h2 className="text-3xl leading-7 font-semibold">
						כניסה עם קוד
						<br /> חד פעמי
					</h2>
					<p className="text-jane-900 leading-4">
						גם אם אם אין לך חשבון <br /> ניצור אותו בהמשך
					</p>
				</span>

				<FaRegIdCard className="w-24 h-24" />
			</div>
			<form onSubmit={handleSubmit(idLogin)} className="flex flex-col gap-4">
				<span>
					<IdField errors={errors} register={register} requierd />
				</span>
				<Button primary type="submit" disabled={loading}>
					<span className="p-1 text-base md:p-0">המשך</span>
				</Button>
			</form>
			<GoogleButton setError={setErrorMessage} onLogin={onLogin} />
		</div>
	);
}

export default IdStep;
