/* eslint-disable @next/next/no-img-element */
import React, {
	Fragment,
	useContext,
	useMemo,
	useState,
	useEffect,
	useRef,
	createRef,
} from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, SearchIcon, XIcon } from "@heroicons/react/outline";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { useRouter } from "next/router";
import Link from "next/link";
import { classNames } from "util/common";
import { AuthContext } from "../contexts/AuthContext";
import { StoresCartsHeader } from "components/account/StoresCartsHeader";
import { LoginBoxModal } from "components/modals/LoginBoxModal";
import { useHttp } from "components/hooks/useHttp";
import { makeApiUrl } from "util/common";
import DebounceInputTopbar from "components/tables/server-table/DebounceInputTopbar";
import { ProductTypeUI, VARIANT } from "components/products/ProductTypeUI";
import { listProductTypes } from "util/types";
import HoverMenu from "./HoverMenu";
import { AnalyticsContext } from "components/contexts/AnalyticsProvider";
import { MdAccountCircle } from "react-icons/md";
import { FaChevronDown } from "react-icons/fa";
import { useAtom } from "jotai";
import { showSearchbarAtom } from "components/jotai/searchAtom";
import useWindowDimensions from "components/hooks/useWindowDimensions";
import { WBO } from "util/types";

export default function HoverTopbar({}) {
	const { authenticated, claims, logout } = useContext(AuthContext);
	const [searchEntireWebsiteQuery, setSearchEntireWebsiteQuery] =
		useState(null);
	const [loading, setLoading] = useState(false);
	const [searchResults, setSearchResults] = useState(null);
	const [searchQuery, setSearchQuery] = useState("");
	const [showSearchbar, setShowSearchbar] = useAtom(showSearchbarAtom);
	const { $post } = useHttp();
	const { collectAnalytics } = useContext(AnalyticsContext);
	const { width } = useWindowDimensions();
	const handleServerResponse = res => {
		const resItems = res?.data || null;
		setSearchResults(resItems);
	};

	useEffect(() => {
		const fetchData = async reqData => {
			const url = makeApiUrl("widget/allwebsite/");
			try {
				setLoading(true);
				const res = await $post(url, { ...reqData });
				if (res.ok) {
					handleServerResponse(res);
				} else {
					throw res.error.message;
				}
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		};

		const q = searchEntireWebsiteQuery || null;
		if (q !== null && typeof q === "string") {
			collectAnalytics("searchbar_query", { query: q });
			void fetchData({ tbl: { q }, all_website_search: true });
		}
	}, [searchEntireWebsiteQuery]);

	const router = useRouter();
	const { asPath, pathname } = router;
	const isCartOrCheckout =
		router.pathname.includes("/checkout") || router.pathname.includes("/cart");
	const searchbarRef = useRef(null);
	const searchbarMobileRef = useRef(null);
	const mobileMenuRef = useRef(null);
	const searchIconMobileRef = useRef(null);
	const searchIconMobileLoggedInRef = useRef(null);
	const mobileMenuPanelRef = useRef(null);
	const mobileSearchBarRef = useRef(null);
	const navRef = useRef(null);

	useEffect(() => {
		resetSearchbar();
	}, [pathname]);

	useEffect(() => {
		const handleClickOutside = event => {
			const isOutsideClickSearch = !navRef.current?.contains(event.target);

			const isMenuOpen =
				mobileMenuRef.current?.getAttribute("data-open") === "true";

			const isOutsideMenu =
				!mobileMenuRef.current?.contains(event.target) &&
				!mobileMenuPanelRef.current?.contains(event.target);

			if (isOutsideMenu && isMenuOpen) {
				mobileMenuRef.current?.click();
			}

			if (isOutsideClickSearch) {
				setSearchResults(null);
				setShowSearchbar(false);
				setLoading(false);
				setSearchQuery("");
			}
		};

		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);

	const menuItems = useMemo(
		() => [
			{
				label: "מוצרים",
				path: "/products/",
				subMenuItems: [
					{ label: "כל המוצרים", path: "/products/", hide: WBO["md"] < width },
					{
						label: "סוג",
						subSubMenuItems: [
							{ label: "תפרחת", path: "/products/flower/" },
							{ label: "שמן", path: "/products/oil/" },
							{ label: "גליליות", path: "/products/preroll/" },
						],
					},
					{
						label: "אפיון",
						subSubMenuItems: [
							{ label: "אינדיקה", path: "/products/families/indica" },
							{ label: "סאטיבה", path: "/products/families/sativa" },
							{ label: "היבריד", path: "/products/families/hybrid/" },
						],
					},
					{
						label: "מינון",
						path: "/categories/",
						subSubMenuItems: [
							{ label: "כל המינונים", path: "/categories/" },
							{ label: "עתירי THC", path: "/products/thc/" },
							{ label: "מאוזנים", path: "/products/balanced/" },
							{ label: "עתירי CBD", path: "/products/cbd/" },
						],
					},
					{
						label: "חדשים",
						path: "/products/newest/",
					},
					{
						label: "בקרוב",
						path: "/products/soon/",
					},
					{
						label: "מוזלים",
						path: "/products/discounts/",
					},
					{
						label: "גנטיקות",
						path: "/strains/",
					},
					{
						label: "אצוות",
						path: "/batches/",
					},
					{
						label: "ביקורות",
						path: "/reviews/",
					},
					// {
					// 	label: "הנצפים",
					// 	path: "/products/most-viewed/",
					// },
				],
			},

			{
				label: "בתי מרקחת",
				path: "/stores/",
				subMenuItems: [
					{ label: "כל בתי המרקחת", path: "/stores/", hide: WBO["md"] < width },
					{ label: "לפי ערים", path: "/cities/" },
					{ label: "מבצעים", path: "/stores/sales/" },
					{ label: "ממוצע הנחות", path: "/stores/discounts/" },
					{ label: "הסל הזול", path: "/desired-cart/" },
				],
			},
			{
				label: "יצרנים",
				subMenuItems: [
					{ label: "מגדלים", path: "/manufacturers/" },
					{ label: "משווקים", path: "/marketers/" },
					// { label: "מטפחים", path: "???" }, IN THE FUTURE - Does not exist now
				],
			},
		],
		[width],
	);

	const getActiveItemLabel = item => {
		if (["", "/"].indexOf(asPath) !== -1) {
			return "מוצרים";
		}

		if (item.path === asPath) {
			return item.label;
		}

		if (item.subMenuItems) {
			const hasActiveLinks = item.subMenuItems.some(i => i.path === asPath);
			const hasActiveSubLinks = item.subMenuItems.some(i =>
				i.subSubMenuItems?.some(si => si.path === asPath),
			);

			if (hasActiveLinks || hasActiveSubLinks) {
				return item.label;
			}
		} else {
			return item.path === asPath ? item.label : "";
		}
	};

	const isActive = item => item.label === getActiveItemLabel(item);

	let fullName = "";
	let email = "";

	if (authenticated) {
		fullName = claims.full_name;
		email = claims.email;
	}

	const isCheckout = useMemo(() => {
		return asPath.includes("/cart") || asPath.includes("/checkout");
	}, [asPath]);

	const onEntireWebsiteSearchBounce = query => {
		if (query.length > 0) {
			setSearchResults(null);
		}
		if (query === "") {
			setSearchResults(null);
			setLoading(false);
		}
		setSearchQuery(query);
		setSearchEntireWebsiteQuery(query);
	};

	const resetSearchbar = () => {
		setSearchResults(null);
		setShowSearchbar(false);
		setLoading(false);
		setSearchQuery("");
	};

	const renderSearchResults = searchResults => {
		const desiredOrder = [
			{ key: "products", heb: "מוצרים" },
			{ key: "cities", heb: "ערים" },
			{ key: "stores", heb: "בתי מרקחת" },
			{ key: "marketers", heb: "משווקים" },
			{ key: "manufacturers", heb: "מגדלים" },
			{ key: "strains", heb: "זנים" },
			{ key: "product_series", heb: "סדרות" },
			{ key: "manufacturer_series", heb: "מותגים" },
		];

		const hasResults = desiredOrder.some(
			key => searchResults[key.key]?.length > 0,
		);

		if (!hasResults) {
			return <p className="py-1 px-2">אין תוצאות לחיפוש</p>;
		}

		return desiredOrder.map(key => {
			const value = searchResults[key.key] || [];
			if (value.length === 0) {
				return null;
			}
			return (
				<Disclosure defaultOpen key={key.key}>
					{({ open }) => (
						<div>
							<Disclosure.Button className="py-3 text-right px-2 bg-gray-200 flex justify-between w-full">
								{key.heb}
								<ChevronUpIcon
									className={`${
										open ? "rotate-180 transform" : ""
									} h-5 w-5 text-jane-500`}
								/>
							</Disclosure.Button>
							<Disclosure.Panel className="text-gray-500 flex flex-col">
								{value.map(val => {
									switch (key.key) {
										case "products":
											return (
												<a
													className="flex flex-col border-t-2 py-1 px-2 cursor-pointer"
													onClick={() => {
														window.location.href = `/products/${val.id}`;
														resetSearchbar();
														collectAnalytics("searchbar_product", {
															product_id: val.id,
														});
													}}
												>
													<div className="flex items-center gap-2">
														<img
															src={
																val?.main_img_thumbnail_url ||
																"/product-placeholder.svg"
															}
															className="w-10 h-10 aspect-square"
															alt={val?.heb_name}
														/>

														<div className="flex flex-col">
															<div className="text-gray-500 font-medium leading-4">
																{val?.heb_name}
															</div>
															<div className="text-gray-400 leading-4">
																{val?.marketer_heb_name}
															</div>
														</div>
													</div>
													<div className="flex justify-start gap-0.5 w-full">
														<ProductTypeUI
															productType={listProductTypes.find(
																productType => {
																	return productType.label === val.product_type;
																},
															)}
															variant={VARIANT.SMALLTEXT}
														/>
														<ProductTypeUI
															productType={listProductTypes.find(
																productType => {
																	return productType.label === val.category;
																},
															)}
															variant={VARIANT.SMALLTEXT}
														/>
														<ProductTypeUI
															productType={listProductTypes.find(
																productType => {
																	return productType.label === val.family;
																},
															)}
															variant={VARIANT.SMALLTEXT}
														/>
													</div>
												</a>
											);
										case "cities":
											return (
												<a
													onClick={() => {
														window.location.href = `/cities/${val.id}`;
														resetSearchbar();
														collectAnalytics("searchbar_city", {
															city_id: val.id,
														});
													}}
													className="flex border-t-2 py-1 px-2 items-center gap-2 cursor-pointer"
												>
													<div className="flex flex-col">
														<div className="text-gray-500 font-medium leading-4">
															{val?.heb_name}
														</div>
														<div className="text-gray-400 leading-4">
															{val?.eng_name}
														</div>
													</div>
												</a>
											);
										case "stores":
											return (
												<a
													onClick={() => {
														window.location.href = `/stores/${
															!!val?.display_name_eng
																? val.display_name_eng
																: val.id
														}`;
														resetSearchbar();
														collectAnalytics("searchbar_store", {
															store_id: val.id,
														});
													}}
													className="flex border-t-2 py-1 px-2 items-center gap-2 cursor-pointer"
												>
													<img
														src={
															val?.logo_thumbnail_url
																? val?.logo_thumbnail_url
																: val?.logo_url
														}
														className="w-10 h-10 aspect-square"
														alt=""
													/>
													<div className="flex flex-col">
														<div className="text-gray-500 font-medium leading-4">
															{val?.display_name}
														</div>
														<div className="text-gray-400 leading-4">
															{val?.city?.heb_name}
														</div>
													</div>
												</a>
											);
										case "marketers":
											return (
												<a
													onClick={() => {
														window.location.href = `/marketers/${val.id}`;
														resetSearchbar();
														collectAnalytics("searchbar_marketer", {
															marketer_id: val.id,
														});
													}}
													key={val.id}
													className="flex border-t-2 py-1 px-2 items-center gap-2 cursor-pointer"
												>
													<img
														src={val?.logo_url}
														className="w-10 h-10 aspect-square"
														alt=""
													/>
													<div className="flex flex-col">
														<div className="text-gray-500 font-medium">
															{val?.heb_name}
														</div>
														<div className="text-gray-400">{val?.eng_name}</div>
													</div>
												</a>
											);
										case "manufacturers":
											return (
												// <Link key={val.id} href={`/manufacturers/${val.id}`}>
												<a
													onClick={() => {
														window.location.href = `/manufacturers/${val.id}`;
														resetSearchbar();
														collectAnalytics("searchbar_manufacturer", {
															manufacturer: val.id,
														});
													}}
													key={val.id}
													className="flex border-t-2 py-1 px-2 items-center gap-2 cursor-pointer"
												>
													<img
														src={val?.logo_url}
														className="w-10 h-10 aspect-square"
														alt=""
													/>
													<div className="flex flex-col">
														<div className="text-gray-500 font-medium leading-4">
															{val?.heb_name}
														</div>
														<div className="text-gray-400 leading-4">
															{val?.eng_name}
														</div>
													</div>
												</a>
												// </Link>
											);
										case "strains":
											return (
												<a
													onClick={() => {
														window.location.href = `/strains/${val.id}`;

														resetSearchbar();
														collectAnalytics("searchbar_strain", {
															strain_id: val.id,
														});
													}}
													key={val.id}
													className="flex border-t-2 py-1 px-2 items-center gap-2 cursor-pointer"
												>
													<div className="flex flex-col">
														<div className="text-gray-500 font-medium leading-4">
															{val?.heb_name}
														</div>
														<div className="text-gray-400 leading-4">
															{val?.eng_name}
														</div>
													</div>
												</a>
											);
										case "product_series":
											return (
												<a
													onClick={() => {
														window.location.href = `/products/?filters=series_id^in^${val?.heb_name}%26${val.id}`;
														resetSearchbar();
														collectAnalytics("searchbar_product_series", {
															series_id: val.id,
														});
													}}
													key={val.id}
													className="flex border-t-2 py-1 px-2 items-center gap-2 cursor-pointer"
												>
													<div className="flex flex-col">
														<div className="text-gray-500 font-medium leading-4">
															{val?.heb_name}
														</div>
														<div className="text-gray-400 leading-4">
															{val?.eng_name}
														</div>
													</div>
												</a>
											);
										case "manufacturer_series":
											return (
												<a
													onClick={() => {
														window.location.href = `/products/?filters=manufacturer_series_id^in^${val?.heb_name}%26${val.id}`;
														resetSearchbar();
														collectAnalytics("searchbar_manufacturer_series", {
															brand_id: val.id,
														});
													}}
													key={val.id}
													className="flex border-t-2 py-1 px-2 items-center gap-2 cursor-pointer"
												>
													<div className="flex flex-col">
														<div className="text-gray-500 font-medium leading-4">
															{val?.heb_name}
														</div>
														<div className="text-gray-400 leading-4">
															{val?.eng_name}
														</div>
													</div>
												</a>
											);
										default:
											return null;
									}
								})}
							</Disclosure.Panel>
						</div>
					)}
				</Disclosure>
			);
		});
	};

	const handleSearchQueryChange = newQuery => {
		setSearchQuery(newQuery);
	};

	const refs = useMemo(() => {
		return (
			menuItems.map(() => {
				return createRef();
			}) ?? []
		);
	}, []);

	const subRefs = useMemo(() => {
		const subMenuItems = menuItems
			?.filter(item => item.subMenuItems?.map(si => si))
			.flat();
		return (
			subMenuItems?.map(() => {
				return createRef();
			}) ?? []
		);
	}, [menuItems]);

	function handleClosingOthers(id, currRef, menuOpen, isSubRef = false) {
		if (menuOpen && !isSubRef) {
			currRef.current?.click();
		}

		if (isSubRef) {
			const otherSubRefs = subRefs.filter(ref => {
				return ref?.current?.getAttribute("data-id") !== id;
			});

			otherSubRefs.forEach(ref => {
				const isOpen = ref?.current?.getAttribute("data-open") === "true";
				if (isOpen) {
					ref.current?.click();
				}
			});

			if (menuOpen) {
				currRef.current?.click();
			}

			return;
		}

		const otherRefs = refs.filter(ref => {
			return ref?.current?.getAttribute("data-id") !== id;
		});

		otherRefs.forEach(ref => {
			const isOpen = ref?.current?.getAttribute("data-open") === "true";
			if (isOpen) {
				ref.current?.click();
			}
		});

		if (menuOpen) {
			currRef.current?.click();
		}
	}

	useEffect(() => {
		if (mobileSearchBarRef.current && showSearchbar) {
			mobileSearchBarRef.current.focus();
		}
	}, [showSearchbar]);

	return (
		<Disclosure
			as="nav"
			ref={navRef}
			className="bg-white shadow fixed w-full z-50 text-base sm:text-sm"
		>
			{({ open, close }) => (
				<>
					<div className="flex flex-col w-full">
						<div
							className={`h-14 lg:h-16 relative flex flex-col lg:flex-row gap-1 lg:gap-0 main-width mx-auto px-2 md:px-4 justify-center`}
						>
							<div className="relative flex main-width ">
								<div className="absolute inset-y-0 start-0 flex items-center gap-3 lg:hidden h-14">
									{/* Mobile menu button */}
									<Disclosure.Button
										onClick={() => {
											collectAnalytics("mobile_menu_clicked");
											setShowSearchbar(false);
										}}
										ref={mobileMenuRef}
										data-open={open}
										className="inline-flex items-center justify-center rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-jane-500"
									>
										<div>
											<XIcon
												className={`block h-6 w-6 ${open ? "flex" : "hidden"}`}
												aria-hidden="true"
											/>
											<MenuIcon
												className={`block h-6 w-6 ${open ? "hidden" : "flex"}`}
												aria-hidden="true"
											/>
										</div>
									</Disclosure.Button>
									<div className="">
										<StoresCartsHeader />
									</div>
								</div>
								<div className="flex-1 flex items-center justify-center lg:items-center lg:justify-start h-full">
									<div className="flex h-full flex-grow justify-center">
										<div className="flex-shrink-0 flex items-center">
											<Link href="/products/">
												<a className="focus:ring-2 focus:ring-offset-2 focus:ring-jane-500 rounded-md px-2 pt-1 -mr-1 flex flex-col items-center">
													<img
														src="/jane-logo.svg"
														alt="Jane"
														className="h-[32px] lg:h-[36px] w-auto"
													/>
													<span className="text-xs">מבית קנאביז</span>
												</a>
											</Link>
										</div>

										<div className="hidden ms-4 lg:flex h-full w-full">
											{menuItems
												?.filter(item => !item.hide)
												.map((item, idx) => {
													return (
														<div key={idx} className="flex items-center h-full">
															{item.subMenuItems ? (
																<HoverMenu
																	key={idx}
																	name={item.label}
																	menuItems={item.subMenuItems.filter(
																		item => !item.hide,
																	)}
																	className={
																		isActive(item) &&
																		"bg-jane-100 border-b-2 border-b-jane-500"
																	}
																	isLinkAlsoAButton={item.path ? true : false}
																	url={item.path}
																/>
															) : (
																<Link href={item.path}>
																	<a
																		className={classNames(
																			`box-border px-4 text-base cursor-pointer border-b-2 hover:border-gray-500 border-transparent hover:bg-jane-100 h-full flex items-center gap-1`,
																			isActive(item) &&
																				"bg-jane-100 border-b-jane-500",
																		)}
																	>
																		{item.label}
																		{item.tag?.toLowerCase() === "new" && (
																			<span className="font-semibold text-xs bg-red-600 px-1 py-0 text-center flex items-center justify-center rounded-full text-white">
																				חדש
																			</span>
																		)}
																	</a>
																</Link>
															)}
														</div>
													);
												})}
										</div>

										{!isCartOrCheckout && (
											<div
												ref={searchbarRef}
												className=" bg-white relative w-full hidden lg:flex lg:flex-col px-2 md:px-4"
											>
												<DebounceInputTopbar
													placeholder="חיפוש..."
													onBounce={onEntireWebsiteSearchBounce}
													className="focus:ring-0 focus:border-gray-300 w-full mt-[18px]"
													withGridLayout={() => {}}
													disableBottomBorder={loading || searchResults}
													timeoutMs={300}
													value={searchQuery}
													onChange={handleSearchQueryChange}
												/>
												<div>
													{(loading || searchResults) && (
														<div className="h-10">
															{loading && (
																<div className="bg-white max-w-[1480px] rounded-b-lg border-b-[1px] border-x-[1px] border-gray-300">
																	<p className="px-2 py-1">טוען...</p>
																</div>
															)}
															{searchResults && (
																<div className="bg-white max-w-[1480px] rounded-b-lg max-h-80 overflow-y-scroll border-gray-300 border-x-[1px] border-b-[1px]">
																	<div className="flex flex-col">
																		{renderSearchResults(searchResults)}
																	</div>
																</div>
															)}
														</div>
													)}
												</div>
											</div>
										)}
									</div>
								</div>

								{/* Mobile */}
								<div className="absolute inset-y-0 end-0 flex items-center lg:static lg:inset-auto lg:ms-6 lg:pe-0 z-30 h-full">
									{!authenticated ? (
										<div className="flex gap-3 items-center">
											<div className="hidden lg:flex">
												<StoresCartsHeader />
											</div>
											{!isCartOrCheckout && (
												<span className="lg:hidden" ref={searchIconMobileRef}>
													<SearchIcon
														className="w-6 h-6"
														onClick={() => {
															const isMobileMenuOpen =
																mobileMenuRef.current?.getAttribute(
																	"data-open",
																) === "true";
															if (isMobileMenuOpen) {
																mobileMenuRef.current?.click();
																setShowSearchbar(true);
															} else {
																setShowSearchbar(prev => !prev);
															}
														}}
													/>
												</span>
											)}
											<LoginBoxModal
												activator={
													<div className="cursor-pointer hover:underline font-medium">
														<span className="text-sm sm:text-base">
															<MdAccountCircle className="w-6 h-6 rounded-full text-white bg-black" />
														</span>
													</div>
												}
											/>
										</div>
									) : (
										<div className="flex items-center gap-3">
											{!isCartOrCheckout && (
												<span
													className="lg:hidden"
													ref={searchIconMobileLoggedInRef}
												>
													<SearchIcon
														className="w-6 h-6"
														onClick={() => {
															const isMobileMenuOpen =
																mobileMenuRef.current?.getAttribute(
																	"data-open",
																) === "true";
															if (isMobileMenuOpen) {
																mobileMenuRef.current?.click();
																setShowSearchbar(true);
															} else {
																setShowSearchbar(prev => !prev);
															}
														}}
													/>
												</span>
											)}
											<Menu
												as="div"
												className="relative flex items-center gap-3 h-full"
											>
												<div className="hidden lg:flex">
													<StoresCartsHeader />
												</div>
												<div className="flex items-center justify-center">
													<Menu.Button
														className={`bg-white rounded-full flex gap-1 text-sm items-center flex-row-reverse sm:flex-row ${
															authenticated &&
															"border-2 p-[1px] border-emerald-500"
														}`}
													>
														<MdAccountCircle className="w-6 h-6 rounded-full text-white bg-jane-800" />
													</Menu.Button>
												</div>
												{/* Profile dropdown */}
												<div className="flex relative">
													<Transition
														as={Fragment}
														enter="transition ease-out duration-200"
														enterFrom="transform opacity-0 scale-95"
														enterTo="transform opacity-100 scale-100"
														leave="transition ease-in duration-75"
														leaveFrom="transform opacity-100 scale-100"
														leaveTo="transform opacity-0 scale-95"
													>
														<Menu.Items className="absolute top-1.5 left-5 w-48 rounded-md shadow-sm py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
															<div className="px-4 py-3 text-xs">
																<div className="text-gray-500">{fullName}</div>
																<div className="text-gray-600">{email}</div>
															</div>
															<Menu.Item>
																{({ active }) => (
																	<div
																		onClick={() => router.push("/account/")}
																		className={classNames(
																			active ? "bg-gray-100" : "",
																			"block px-4 py-3 text-sm text-gray-700 cursor-pointer",
																		)}
																	>
																		החשבון שלי
																	</div>
																)}
															</Menu.Item>
															<Menu.Item>
																{({ active }) => (
																	<div
																		onClick={() => logout()}
																		className={classNames(
																			active ? "bg-gray-100" : "",
																			"block px-4 py-3 text-sm text-gray-700 cursor-pointer",
																		)}
																	>
																		יציאה
																	</div>
																)}
															</Menu.Item>
														</Menu.Items>
													</Transition>
												</div>
											</Menu>
										</div>
									)}
								</div>
							</div>
							{!isCartOrCheckout && showSearchbar && (
								<div
									className=" bg-white main-width shadow-sm  lg:hidden flex flex-col absolute top-14 w-full right-0 px-2 pb-1"
									ref={searchbarMobileRef}
								>
									<DebounceInputTopbar
										placeholder="חיפוש..."
										onBounce={onEntireWebsiteSearchBounce}
										className="focus:ring-0 focus:border-gray-300"
										withGridLayout={() => {}}
										disableBottomBorder={loading || searchResults}
										timeoutMs={300}
										value={searchQuery}
										onChange={handleSearchQueryChange}
										ref={mobileSearchBarRef}
									/>
									<div>
										{(loading || searchResults) && (
											<div className="h-10">
												{loading && (
													<div className="bg-white max-w-[1480px] rounded-b-lg border-b-[1px] border-x-[1px] border-gray-300">
														<p className="px-2 py-1">טוען...</p>
													</div>
												)}
												{searchResults && (
													<div className="bg-white max-w-[1480px] rounded-b-lg max-h-80 overflow-y-scroll border-gray-300 border-x-[1px] border-b-[1px]">
														<div className="flex flex-col">
															{renderSearchResults(searchResults)}
														</div>
													</div>
												)}
											</div>
										)}
									</div>
								</div>
							)}
						</div>
					</div>

					<Disclosure.Panel
						className="lg:hidden"
						ref={mobileMenuPanelRef}
						data-open={open}
					>
						<div className="pt-1 text-base select-none">
							{menuItems.map((item, idx) => (
								<div key={idx}>
									<Disclosure>
										{({ open: menuOpen, close: menuClose }) => {
											return (
												<>
													{item.subMenuItems ? (
														<Disclosure.Button
															key={idx}
															ref={refs[idx] ? refs[idx] : null}
															data-id={item.path}
															data-open={menuOpen}
															as="div"
															href={item.path}
															onClick={() => {
																handleClosingOthers(
																	item.path,
																	refs[idx],
																	menuOpen,
																);
															}}
															className={classNames(
																"flex justify-between items-center ps-3 pe-4 py-3  text-base font-medium cursor-pointer",
																menuOpen
																	? "bg-jane-100 border-jane-500 text-gray-500"
																	: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700",
															)}
														>
															{item.label}
															{item.subMenuItems?.length > 1 && (
																<FaChevronDown
																	className={`transition-transform ${
																		menuOpen ? "rotate-180" : "rotate-0"
																	}`}
																/>
															)}
														</Disclosure.Button>
													) : (
														<Link key={item.path} href={item.path}>
															<a
																className={`flex items-center ps-3 pe-4 py-3 border-l-4 text-base font-medium text-gray-500	border-transparent hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 cursor-pointer`}
																onClick={close}
															>
																<span>{item.label}</span>
																{item.tag === "new" && (
																	<span className="ms-2 font-semibold text-xs bg-red-600 px-1 py-0 text-center flex items-center justify-center rounded-full text-white">
																		חדש
																	</span>
																)}
															</a>
														</Link>
													)}

													<Disclosure.Panel>
														{item.subMenuItems?.map((subMenuItem, subIdx) => {
															return (
																<Disclosure
																	key={subIdx}
																	className={"flex items-center mr-2"}
																>
																	{({ open: subMenuOpen, close }) => (
																		<>
																			<Disclosure.Button
																				data-id={subMenuItem.label}
																				data-open={subMenuOpen}
																				as="div"
																				ref={
																					subRefs[subIdx]
																						? subRefs[subIdx]
																						: null
																				}
																				className={classNames(
																					"flex items-center w-[100%] justify-between text-md text-gray-500 hover:text-gray-700 border border-t-0 border-l-0 border-r-0 border-b-gray-100 last:border-b-0 text-start hover:bg-jane-50",
																				)}
																				onClick={() => {
																					handleClosingOthers(
																						subMenuItem.label,
																						subRefs[subIdx] || null,
																						subMenuOpen,
																						true,
																					);
																				}}
																			>
																				<Disclosure>
																					{() => (
																						<>
																							{subMenuItem.subSubMenuItems ? (
																								<Disclosure.Button
																									data-id={subMenuItem.label}
																									data-open={subMenuOpen}
																									onClick={close}
																									className={`w-full`}
																								>
																									<span
																										className={classNames(
																											"flex items-center gap-2 py-3 px-2 text-gray-600 hover:text-gray-700",
																											!subMenuItem
																												.subSubMenuItems
																												?.length && "px-6",
																										)}
																									>
																										{subMenuItem.subSubMenuItems
																											?.length > 0 && (
																											<span className="transition-transform duration-75">
																												<ChevronDownIcon
																													className={`transition-transform duration-75 text-md w-[15px] ${
																														subMenuOpen
																															? "rotate-0"
																															: "rotate-90"
																													}
																												`}
																												/>
																											</span>
																										)}
																										{subMenuItem?.label}
																									</span>
																								</Disclosure.Button>
																							) : (
																								<Link
																									key={subMenuItem.path}
																									href={subMenuItem.path}
																								>
																									<a
																										className={`flex items-center w-full ps-7 pe-4 py-3 border-l-4 text-base font-medium text-gray-500	border-transparent  hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 cursor-pointer`}
																										onClick={menuClose}
																									>
																										<span>
																											{subMenuItem.label}
																										</span>
																										{subMenuItem.tag ===
																											"new" && (
																											<span className="ms-2 font-semibold text-xs bg-red-600 px-1 py-0 text-center flex items-center justify-center rounded-full text-white">
																												חדש
																											</span>
																										)}
																									</a>
																								</Link>
																							)}
																						</>
																					)}
																				</Disclosure>
																			</Disclosure.Button>
																			<Disclosure.Panel>
																				{subMenuItem.subSubMenuItems?.map(
																					subSubMenuItem => {
																						return (
																							<Link
																								key={subSubMenuItem.path}
																								href={subSubMenuItem.path}
																							>
																								<a
																									className={`block ps-7 pe-4 py-3 border-l-4 text-sm font-medium text-gray-500 border-transparent hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 cursor-pointer`}
																									onClick={close}
																								>
																									<div className="flex gap-1 items-center">
																										<span className="text-[18px]">
																											-
																										</span>
																										<span>
																											{subSubMenuItem.label}
																										</span>
																									</div>
																								</a>
																							</Link>
																						);
																					},
																				)}
																			</Disclosure.Panel>
																		</>
																	)}
																</Disclosure>
															);
														})}
													</Disclosure.Panel>
												</>
											);
										}}
									</Disclosure>
									{idx !== menuItems.length - 1 && (
										<div className="w-[calc(100%-24px)] mx-auto h-[1px] bg-slate-200" />
									)}
								</div>
							))}
						</div>
					</Disclosure.Panel>
				</>
			)}
		</Disclosure>
	);
}
