import useWindowDimensions from "components/hooks/useWindowDimensions";
import { useState } from "react";
import { WBO } from "util/types";

export const VARIANT = {
	BUTTON: "button",
	TEXT: "text",
	SMALLTEXT: "smalltext",
};

const familyLabels = ["hybrid", "sativa", "indica"];

export function ProductTypeUI({
	productType,
	variant = VARIANT.TEXT,
	isActive = false,
}) {
	const { width } = useWindowDimensions();
	const VARIANT_CLASS_NAMES = {
		[VARIANT.BUTTON]: {
			WRAPPER:
				"h-[32px] w-[32px] md:h-[50px] md:w-[65px] px-[1px] box-border border-2 border-[#FFD966] bg-[#FFD966] flex items-center text-center  text-[#785D0C] rounded box-border transition-all",
			CONTENT: "flex items-center m-auto ",
			ICON: "h-[21px] md:h-[30px] w-full md:-mt-1",
			CAPTION:
				"text-[10px] md:text-[13px] font-semibold leading-[10px] p-1 md:p-0 break-words flex flex-col",
		},
		[VARIANT.TEXT]: {
			WRAPPER:
				" w-[32px] h-[42px] sm:h-[53px] sm:w-[42px] md:w-[44.5px] lg:w-[45.5px] lg:h-[59.5px] border-2 border-[#FFD966] bg-[#FFD966] flex items-center text-center  text-white",
			CONTENT: "flex items-center flex-col m-auto ",
			ICON: "h-[24px] w-[24px] sm:h-[30px] sm:w-[30px] lg:h-[32px] lg:w-[32px]",
			CAPTION:
				"text-[8px] sm:text-[10px] lg:text-[12px] font-semibold leading-3",
		},
		[VARIANT.SMALLTEXT]: {
			WRAPPER: `h-[16px] sm:h-[20px] border-2 flex items-center text-center  text-white rounded-sm px-1`,
			CONTENT: "flex items-center flex-col m-auto whitespace-pre",
			CAPTION:
				"text-xs sm:text-sm font-semibold leading-[8px] sm:leading-[9px]",
		},
	};
	const [isHovering, setHovering] = useState(false);
	const backgroundColor =
		variant !== "smalltext" && !isHovering
			? productType.backgroundColor
			: productType.hoverBackgroundColor;
	const textColor = !isHovering
		? productType.textColor
		: productType.hoverTextColor;

	const getTypeWidth = type => {
		switch (type) {
			case "family":
				return "50px";
			case "category":
				return "52px";
			case "product_type":
				return "50px";
		}
	};

	if (variant === "smalltext") {
		return (
			<button
				onMouseEnter={e => setHovering(true)}
				onMouseLeave={e => setHovering(false)}
				style={{
					border: `0px solid ${productType.backgroundColor}`,
					backgroundColor: productType.activeBgColor,
					color: productType.activeTextColor,
					boxShadow: isActive && "0px 0px 5px -1px rgba(0,0,0,0.75)",
					width: getTypeWidth(productType.type),
				}}
				className={VARIANT_CLASS_NAMES[variant].WRAPPER}
			>
				<div className={VARIANT_CLASS_NAMES[variant].CONTENT}>
					<p className={VARIANT_CLASS_NAMES[variant].CAPTION}>
						{familyLabels.includes(productType.label)
							? productType.longLabel
							: productType.value}
					</p>
				</div>
			</button>
		);
	}

	return (
		<button
			onMouseEnter={e => {
				if (width > WBO["lg"]) {
					setHovering(true);
				}
			}}
			onMouseLeave={e => {
				if (width > WBO["lg"]) {
					setHovering(false);
				}
			}}
			onClick={() => {
				if (isActive && width < WBO["lg"]) {
					setHovering(false);
				}
			}}
			style={{
				border: isActive
					? `2px solid ${productType.activeBorderColor}`
					: `0px solid ${productType.backgroundColor}`,
				backgroundColor: !isActive
					? backgroundColor
					: productType.activeBgColor,
				color: !isActive ? textColor : productType.activeTextColor,
				boxShadow: isActive && "0px 0px 5px -1px rgba(0,0,0,0.75)",
			}}
			className={VARIANT_CLASS_NAMES[variant].WRAPPER}
		>
			<div className={VARIANT_CLASS_NAMES[variant].CONTENT}>
				<p className={`${VARIANT_CLASS_NAMES[variant].CAPTION} md:flex hidden`}>
					{productType.icon && (
						<img
							src={productType.icon}
							alt={productType.longLabel}
							className={VARIANT_CLASS_NAMES[variant].ICON}
						/>
					)}
					{productType.longLabel}
				</p>
				<div className="md:hidden">
					{productType.icon ? (
						<img
							src={productType.icon}
							alt={productType.value}
							className={VARIANT_CLASS_NAMES[variant].ICON}
						/>
					) : (
						<p className={VARIANT_CLASS_NAMES[variant].CAPTION}>
							{productType.value}
						</p>
					)}
				</div>
			</div>
		</button>
	);
}
