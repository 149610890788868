import { AnalyticsContext } from "components/contexts/AnalyticsProvider";
import { AuthContext } from "components/contexts/AuthContext";
import { useApi } from "components/hooks/useApi";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FaGoogle } from "react-icons/fa";
import { toastMessage } from "util/common";

const GoogleButton = ({ setError, onLogin }) => {
	const api = useApi();
	const { collectAnalytics } = useContext(AnalyticsContext);
	const { checkLogin } = useContext(AuthContext);
	const [scriptLoaded, setScriptLoaded] = useState(!!window.gapi);
	const buttonRef = useRef();
	useEffect(() => {
		if (!scriptLoaded) {
			const script = document.createElement("script");
			script.src = "https://accounts.google.com/gsi/client?hl=he";
			script.async = true;
			script.onload = () => setScriptLoaded(true);
			document.body.appendChild(script);

			return () => {
				if (script.parentNode) {
					script.parentNode.removeChild(script);
				}
			};
		}
	}, [scriptLoaded, setError]);

	useEffect(() => {
		if (!scriptLoaded) return;

		google.accounts.id.initialize({
			client_id:
				"826223448842-tlkhl5ui7rklibccmau63uu3vq1k4j4a.apps.googleusercontent.com",
			cancel_on_tap_outside: false,
			callback: async resp => {
				const token = resp.credential;
				const params = { token };
				try {
					const res = await api.submitGoogleLogin(params);
					if (res.ok) {
						collectAnalytics("end_user_login", { method: "google" });
						checkLogin();
						toastMessage(`התחברת לחשבונך בהצלחה`, "success");
						onLogin();
					} else {
						throw res.error.message;
					}
				} catch (error) {
					setError(error);
				}
			},
		});
		google.accounts.id.renderButton(buttonRef.current, {
			type: "icon",
			size: "large",
			theme: "filled_blue",
		});
	}, [scriptLoaded, api, collectAnalytics, checkLogin, setError, onLogin]);

	const handleButtonClick = () => {
		if (buttonRef.current) {
			const googleButton =
				buttonRef.current.querySelector('div[role="button"]');
			if (googleButton) {
				googleButton.click();
			}
		}
	};
	return (
		<>
			{!scriptLoaded ? (
				<div className="border flex items-center justify-center gap-2 h-[40px] bg-slate-300 cursor-not-allowed rounded-md animate-[pulse_2s_ease-in-out_infinite] px-3">
					<FaGoogle className="w-[18px] h-[18px]" />
					כניסה באמצעות Google
				</div>
			) : (
				<div
					className="border flex items-center justify-center gap-2 border-jane-200 rounded hover:bg-jane-100 hover:border-jane-300 cursor-pointer transition-colors h-[40px] px-3"
					onClick={handleButtonClick}
				>
					<div ref={buttonRef} id={`google-login`}>
						{/* Google button will be rendered inside here */}
					</div>
					כניסה באמצעות Google
				</div>
			)}
		</>
	);
};

export default GoogleButton;
