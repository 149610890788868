import React from "react";
import { XIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { Modal } from "./Modal";
import { WarningBox } from "../layout/WarningBox";
import { Button } from "../form/Button";
import { classNames } from "util/common";

export function ModalDialog({
	Ref = null,
	children,
	activator,
	title = "",
	isOpen = false,
	description = "",
	submitBtnText = "אישור",
	submitBtnDanger = false,
	maxHeight = 0,
	error = null,
	disabled = false,
	showCancelButton = true,
	showSubmitButton = true,
	cancelBtnText = "ביטול",
	onSubmit = () => {},
	onChange = isOpen => {},
	maxWidth,
	onClose = () => {},
	gridStylePopUp = false,
	footer = null,
	className = "",
	closeOnClickOutside = false,
	isLoading = false,
}) {
	const [formError, setFormError] = useState(null);

	useEffect(() => {
		setFormError(error);
	}, [error]);

	const onModalChange = isOpen => {
		setFormError(null);
		onChange(isOpen);
	};

	const heightStyle =
		maxHeight && maxHeight > 0 ? { maxHeight, overflowY: "auto" } : {};

	return (
		<Modal
			onClose={onClose}
			activator={activator}
			onChange={onModalChange}
			isOpen={isOpen}
			maxWidth={maxWidth}
			closeOnClickOutside={closeOnClickOutside}
		>
			{({ setOpen }) => {
				const closeDialog = () => {
					setOpen(false);
					if (typeof onClose === "function") {
						onClose();
					}
				};
				return (
					<div className="w-full flex flex-col" ref={Ref}>
						<div className="flex px-3 py-3 sm:px-5 sm:py-4 text-base sm:text-lg text-color-strong items-center">
							{title}
							<div className="flex-grow" />
							<span className="p-1 rounded-md cursor-pointer text-gray-400 transition-colors hover:bg-gray-100 hover:text-gray-600">
								<XIcon
									className={classNames(
										"w-4 h-4",
										gridStylePopUp && "border-2 rounded-full",
									)}
									onClick={closeDialog}
								/>
							</span>
						</div>
						{isLoading && (
							<div className="h-0.5 bg-gray-200 overflow-hidden  w-full">
								<div className="h-full w-[80%] bg-jane-600 animate-slide"></div>
							</div>
						)}
						<div
							className={`bg-gray-50 border-b border-t border-gray-200 relative px-3 py-3 sm:px-5 sm:py-4 text-gray-600 ${className}`}
							style={heightStyle}
						>
							{!!description && (
								<div className="text-gray-400 text-base mb-2">
									{description}
								</div>
							)}

							{typeof children === "function"
								? children({ setOpen })
								: children}
						</div>

						<div className="px-5 py-5 flex flex-col">
							{!!formError && (
								<div className="my-6 max-w-md">
									<WarningBox {...formError} />
								</div>
							)}
							{!!footer && footer}
							<div className="flex flex-row items-center justify-end">
								{!!showCancelButton && (
									<Button
										type="button"
										onClick={closeDialog}
										className="me-2"
										primary={!!gridStylePopUp}
									>
										{cancelBtnText || "ביטול"}
									</Button>
								)}
								{!!showSubmitButton && (
									<Button
										type="button"
										primary={!submitBtnDanger}
										danger={!!submitBtnDanger}
										disabled={!!disabled || isLoading}
										onClick={() => !disabled && onSubmit({ setOpen })}
									>
										{submitBtnText || "אישור"}
									</Button>
								)}
							</div>
						</div>
					</div>
				);
			}}
		</Modal>
	);
}
