import { FieldError } from "components/form/FieldError";
import React, { useContext } from "react";
import { classNames } from "util/common";

function IdField({
	errors,
	register,
	registerName = "id_number",
	placeholder = "מספר תעודת זהות",
	disabled = false,
	required = true,
	label = "תעודת זהות",
	hideLabel = false,
	additionalValidation,
	isUpdated = false,
}) {
	const isIsraeliIdNumber = id => {
		id = String(id).trim();
		if (id.length > 9 || isNaN(id)) return false;
		id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
		return (
			Array.from(id, Number).reduce((counter, digit, i) => {
				const step = digit * ((i % 2) + 1);
				return counter + (step > 9 ? step - 9 : step);
			}) %
				10 ===
			0
		);
	};

	const validateId = value => {
		if (!isIsraeliIdNumber(value)) {
			return "תעודת זהות לא תקינה";
		}
		return true;
	};

	const handleKeyDown = event => {
		const { key, keyCode } = event;
		const isNumberKey = /^[0-9]$/.test(key);
		const isAllowedKey =
			key === "Backspace" ||
			key === "Delete" ||
			key === "Tab" ||
			keyCode === 8 ||
			keyCode === 46 ||
			keyCode === 9;

		if (!isNumberKey && !isAllowedKey && !isArrowKey(event)) {
			event.preventDefault();
		}
	};

	const isArrowKey = event => {
		const arrowKeys = ["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"];
		return arrowKeys.includes(event.key);
	};
	return (
		<div>
			{!hideLabel && (
				<label
					htmlFor={registerName}
					className="block text-base font-medium text-gray-700"
				>
					{label}
				</label>
			)}
			<input
				type="tel"
				id={registerName}
				className={classNames(
					"mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-base focus:ring-1 disabled:cursor-not-allowed text-start",
					!!errors?.[registerName]
						? "border-red-300 focus:ring-red-500 focus:border-red-500 bg-red-50 bg-opacity-50"
						: "border-gray-300 focus:ring-jane-500 focus:border-jane-500",
					disabled && "bg-jane-100",
					isUpdated?.[registerName] && "border-jane-500 bg-jane-300",
				)}
				placeholder={placeholder}
				{...register(registerName, {
					required: required ? "שדה חובה" : false,
					pattern: {
						value: /^[0-9]*$/,
						message: "רק מספרים מותרים",
					},
					maxLength: {
						value: 9,
						message: "ערך לא תקין",
					},
					minLength: {
						value: 9,
						message: "ערך לא תקין",
					},
					validate: validateId,
					...additionalValidation,
				})}
				disabled={disabled}
				autoComplete="off"
				onKeyDown={ev => handleKeyDown(ev, "number")}
			/>
			{errors?.[registerName] && (
				<FieldError message={errors[registerName].message} />
			)}
		</div>
	);
}

export default IdField;
