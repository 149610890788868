import React, { useCallback, forwardRef } from "react";
import _debounce from "lodash/debounce";
import { classNames } from "util/common";

const DebounceInputTopbar = forwardRef(
	(
		{
			placeholder,
			disabled = false,
			onBounce = inputVal => {},
			timeoutMs = 1000,
			className,
			withGridLayout,
			disableBottomBorder = false,
			value,
			onChange,
		},
		ref,
	) => {
		const debounceFn = useCallback(_debounce(handleDebounceFn, timeoutMs), []);

		function handleDebounceFn(inputValue) {
			onBounce(inputValue);
		}

		function handleChange(event) {
			onChange(event.target.value);
			debounceFn(event.target.value);
		}

		return (
			<input
				ref={ref}
				type="search"
				className={classNames(
					"block w-full border-gray-300 rounded-md text-xs h-[30px]",
					!!disabled
						? "bg-gray-100"
						: "focus:ring-jane-500 focus:border-jane-500",
					withGridLayout ? "" : "w-[165px]",
					className,
					disableBottomBorder &&
						value.length > 1 &&
						"border-b-0 rounded-b-none",
				)}
				placeholder={placeholder}
				value={value}
				onChange={handleChange}
				disabled={disabled}
				autoComplete="off"
			/>
		);
	},
);
DebounceInputTopbar.displayName = "DebounceInputTopbar";

export default DebounceInputTopbar;
