import "../styles/globals.css";
import { AuthProvider } from "components/contexts/AuthContext";
import { CacheProvider } from "components/contexts/CacheContext";
import { CsrfProvider } from "components/contexts/CsrfContext";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { AnalyticsProvider } from "../components/contexts/AnalyticsProvider";
import Script from "next/script";
import { ActiveTabProvider } from "components/contexts/ActivateTabProvider";
import NewLayout from "components/layout/NewLayout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useIsInIframe } from "components/hooks/useIsInIframe";
import AdLayoutProvider from "components/layout/AdLayoutProvider";
import { Provider } from "jotai";
import BackToTop from "components/layout/BackToTop";
export default function App({ Component, pageProps }) {
	const router = useRouter();
	const isMeta = router.pathname.includes("meta");
	const getLayout = () => {
		if (
			router.asPath.startsWith("/products") ||
			router.asPath.startsWith("/stores") ||
			router.asPath.startsWith("/cities") ||
			router.asPath.startsWith("/manufacturers") ||
			router.asPath.startsWith("/marketers") ||
			router.asPath.startsWith("/checkout") ||
			router.asPath.startsWith("/terms") ||
			router.asPath.startsWith("/accessibility") ||
			router.asPath.startsWith("/privacy") ||
			router.asPath.startsWith("/account") ||
			router.asPath.startsWith("/strains") ||
			router.asPath.startsWith("/cart") ||
			router.asPath.startsWith("/login") ||
			router.asPath.startsWith("/signup") ||
			router.asPath.startsWith("/reviews") ||
			router.asPath.startsWith("/desired-cart") ||
			router.asPath.startsWith("/categories") ||
			router.asPath.startsWith("/batches")
		) {
			return NewLayout;
		}
		// Default layout if no specific conditions match
		return React.Fragment;
	};

	const LayoutProvider = getLayout();

	return (
		<>
			<Head>
				<meta
					name="viewport"
					content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=3,shrink-to-fit=no"
				/>
				{/* Open Graph Facebook, LinkedIn, Instagram */}
				<meta property="og:title" content="Jane | ג'יין" />
				<meta
					property="og:description"
					content="ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין"
				/>
				<meta
					property="og:image"
					content="https://cdn.jane.co.il/general/thumbnail-image.png"
				/>
				<meta
					property="og:url"
					content={`https://jane.co.il${router.asPath}`}
				/>
				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="627" />
				<meta property="og:type" content="website" />
				{/* Twitter */}
				<meta name="twitter:title" content="Jane | ג'יין" />
				<meta
					name="twitter:description"
					content="ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין"
				/>
				<meta name="twitter:card" content="summary_large_image"></meta>
				<meta
					name="twitter:image"
					content="https://cdn.jane.co.il/general/thumbnail-image.png"
				/>
				{!isMeta && (
					<Script
						id="disability-widget"
						async
						src="https://cdn.enable.co.il/licenses/enable-L155967t2c3n4zyq-1122-37504/init.js"
					></Script>
				)}
				<script
					dangerouslySetInnerHTML={{
						__html: `
						(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
						new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
						j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
						'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
						})(window,document,'script','dataLayer','GTM-M4PKQWZF');
            			`,
					}}
				/>
			</Head>
			<noscript>
				<iframe
					src="https://www.googletagmanager.com/ns.html?id=GTM-M4PKQWZF"
					height="0"
					width="0"
					style={{ display: "none", visibility: "hidden" }}
				></iframe>
			</noscript>
			<CsrfProvider>
				<CacheProvider>
					<AuthProvider>
						<AnalyticsProvider>
							<Provider>
								<LayoutProvider>
									<ActiveTabProvider>
										<AdLayoutProvider>
											<ToastContainer />
											<BackToTop />
											<Component {...pageProps} />
										</AdLayoutProvider>
									</ActiveTabProvider>
								</LayoutProvider>
							</Provider>
						</AnalyticsProvider>
					</AuthProvider>
				</CacheProvider>
			</CsrfProvider>
		</>
	);
}
