import { useIsInIframe } from "components/hooks/useIsInIframe";
import useWindowDimensions from "components/hooks/useWindowDimensions";
import { WBO } from "util/types";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { XIcon } from "@heroicons/react/solid";

export default function AdLayoutProvider({ children }) {
	const isInIframe = useIsInIframe();
	const { width, height } = useWindowDimensions();
	const [isAdLoaded, setIsAdLoaded] = useState(false);
	const [isAdClosed, setIsAdClosed] = useState(false);
	const [show, setShow] = useState(false);
	const router = useRouter();
	const isWidget = router.pathname.includes("widget");
	const isCartOrCheckout =
		router.pathname.includes("cart") || router.pathname.includes("checkout");

	useEffect(() => {
		const timeout = setTimeout(() => {
			setShow(true);
		}, 3000);

		return () => clearTimeout(timeout);
	}, []);

	return (
		<>
			{children}
			{!isInIframe &&
				!isCartOrCheckout &&
				!isWidget &&
				WBO["md"] > width &&
				!isAdClosed && (
					<>
						<span
							className={`bg-white border-black border-[1px] w-4 h-4 rounded-full fixed bottom-12 left-1/2 translate-x-[152px] z-[99] flex justify-center items-center cursor-pointer transition-opacity duration-300 ${
								show ? "opacity-100" : "opacity-0"
							}`}
							onClick={() => setIsAdClosed(true)}
						>
							<XIcon className="w-3 h-3" />
						</span>
						<span
							id="zone677642742"
							className="fixed bottom-0 left-1/2 -translate-x-1/2 z-50 min-w-[320px] h-[50px]"
						></span>
						<script>
							{(function (r, d, u) {
								if (isAdLoaded) return;
								var s = d.createElement(r);
								s.async = true;
								u += "&cb=" + Date.now() + Math.random();
								s.src = u;
								var n = d.getElementsByTagName(r)[0];
								n.parentNode.insertBefore(s, n);
								setIsAdLoaded(true);
							})(
								"script",
								document,
								"https://knbis.engine.adglare.net/?677642742",
							)}
						</script>
					</>
				)}
		</>
	);
}
