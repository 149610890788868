import { apiServerUrl } from "jane.config";
import { makeApiUrl } from "util/common";
import { useHttp } from "./useHttp";

// TODO - NIV - allow other methods like patch / put / delete for more semantic API calls

export function useApi() {
	const { $get, $post } = useHttp();
	return {
		// auth
		getCsrfToken: async () => await $get(`${apiServerUrl}/api/common/csrf/`),
		getObjectsCache: async () => await $get(makeApiUrl(`cache/`)),
		checkEmailAvailability: async params =>
			await $post(makeApiUrl(`store/signup/check/`), params),
		submitRegistration: async params =>
			await $post(makeApiUrl(`store/signup/submit/`), params),
		getChainStores: async () => await $get(makeApiUrl(`common/chain-stores/`)),
		getCities: async () => await $get(makeApiUrl(`common/cities/`)),
		getCitiesForFilter: async () =>
			await $get(makeApiUrl(`common/cities-for-filters/`)),
		getCitiesForFilterShabbat: async () =>
			await $get(makeApiUrl(`common/cities-for-filters/shabbat/`)),
		getCityById: async id => await $get(makeApiUrl(`widget/cities/${id}/`)),
		getCitiesByProductId: async id =>
			await $get(makeApiUrl(`widget/cities/product/${id}/`)),
		//
		getAveragePriceWidget: async () =>
			await $get(makeApiUrl(`widget/avg-price`)),
		getProductMetaWidget: async id =>
			await $get(makeApiUrl(`widget/product/meta/${id}/`)),
		getProduct: async id => await $get(makeApiUrl(`widget/product/${id}/`)),
		//
		submitUserOrder: async params =>
			await $post(makeApiUrl(`shop/order/new/`), params),
		getStoreShop: async id => await $get(makeApiUrl(`shop/store/${id}/`)),
		getStoreProduct: async (storeId, productId) =>
			await $get(makeApiUrl(`store-product-by-id/${storeId}/${productId}/`)),
		//* user license
		getUserLicense: async (userId, licenseId) =>
			await $get(makeApiUrl(`account/${userId}/license/${licenseId}/`)),
		getUserLicenses: async userId =>
			await $get(makeApiUrl(`account/${userId}/license/`)),
		createUserLicense: async (userId, params) =>
			await $post(makeApiUrl(`account/${userId}/license/create/`), params),
		editUserLicense: async (userId, licenseId, params) =>
			await $post(
				makeApiUrl(`account/${userId}/license/${licenseId}/edit/`),
				params,
			),
		softDeleteUserLicense: async (userId, licenseId) =>
			await $post(makeApiUrl(`account/${userId}/license/${licenseId}/delete/`)),
		//* user prescription
		getUserPrescriptions: async userId =>
			await $get(makeApiUrl(`account/${userId}/prescription/`)),
		createUserPrescription: async (userId, params) =>
			await $post(makeApiUrl(`account/${userId}/prescription/create/`), params),
		editUserPrescription: async (userId, prescriptionId, params) =>
			await $post(
				makeApiUrl(`account/${userId}/prescription/${prescriptionId}/edit/`),
				params,
			),
		getUserPrescription: async (userId, prescriptionId) =>
			await $get(
				makeApiUrl(`account/${userId}/prescription/${prescriptionId}/`),
			),
		softDeleteUserPrescription: async (userId, prescriptionId) =>
			await $post(
				makeApiUrl(`account/${userId}/prescription/${prescriptionId}/delete/`),
			),
		//* user
		getUserInfo: async params => await $post(makeApiUrl(`shop/user/`), params),
		getAccountSideInfo: async () =>
			await $get(makeApiUrl(`get-account-side-info/`)),
		updateUserInfo: async params =>
			await $post(makeApiUrl(`shop/user/edit/`), params),
		//* user adress
		getUserAddresses: async userId =>
			await $get(makeApiUrl(`account/${userId}/address/`)),
		createUserAddress: async (userId, params) =>
			await $post(makeApiUrl(`account/${userId}/address/create/`), params),
		editUserAddress: async (userId, addressId, params) =>
			await $post(
				makeApiUrl(`account/${userId}/address/${addressId}/edit/`),
				params,
			),
		deleteUserAddress: async (userId, addressId) =>
			await $post(makeApiUrl(`account/${userId}/address/${addressId}/delete/`)),
		getUserShippingRestrictions: async storeId =>
			await $post(makeApiUrl(`${storeId}/user-address/shipping-restrictions/`)),
		getStoreOrders: async params =>
			await $post(makeApiUrl(`shop/user/orders/`), params),
		getStoreCityDeliveryInfo: async params =>
			await $post(makeApiUrl(`shop/distance/`), params),
		checkEmailAvailability: async params =>
			await $post(makeApiUrl(`store/signup/check/`), params),
		submitRegistration: async params =>
			await $post(makeApiUrl(`store/signup/submit/`), params),
		submitLogin: async params =>
			await $post(makeApiUrl(`account/login/`), params),
		checkLogin: async () => await $get(makeApiUrl(`account/check/`)),
		submitLoginWithToken: async params =>
			await $post(makeApiUrl(`account/login-by-token/`), params),
		submitGoogleLogin: async params =>
			await $post(makeApiUrl(`login/google/`), params),
		submitMicrosoftLogin: async params =>
			await $post(makeApiUrl(`login/microsoft/`), params),
		submitLoginOtp: async params =>
			await $post(makeApiUrl(`account/login/otp/`), params),

		// chain stores
		getChainStores: async () => await $get(makeApiUrl(`common/chain-stores/`)),

		// store
		getStoreShop: async id => await $get(makeApiUrl(`shop/store/${id}/`)),
		getStore: async id => await $get(makeApiUrl(`stores/${id}/`)),
		getStoreCartProducts: async (id, params) =>
			await $post(makeApiUrl(`stores/${id}/cart-products/`), params),
		getStoreDiscounts: async (store_id, params) =>
			await $post(makeApiUrl(`${store_id}/discounts/get_all/`), params),
		getFullStoreDiscounts: async (store_id, params) =>
			await $post(makeApiUrl(`${store_id}/full-discounts/get_all/`), params),
		getStoreDiscountsApply: async (store_id, params) =>
			await $post(makeApiUrl(`${store_id}/discounts/apply/`), params),

		// cities
		getCities: async () => await $get(makeApiUrl(`common/cities/`)),
		getCitiesForFilter: async () =>
			await $get(makeApiUrl(`common/cities-for-filters/`)),
		getCitiesForFilterShabbat: async () =>
			await $get(makeApiUrl(`common/cities-for-filters/shabbat/`)),
		getCityById: async id => await $get(makeApiUrl(`widget/cities/${id}/`)),
		getCitiesByProductId: async id =>
			await $get(makeApiUrl(`widget/cities/product/${id}/`)),

		// product
		getAveragePriceWidget: async () =>
			await $get(makeApiUrl(`widget/avg-price`)),
		getProductMetaWidget: async id =>
			await $get(makeApiUrl(`widget/product/meta/${id}/`)),
		getProduct: async id => await $get(makeApiUrl(`widget/product/${id}/`)),

		// store product
		getStoreProduct: async (id, productId) =>
			await $get(makeApiUrl(`shop/store/${id}/product/${productId}/`)),

		// orders
		submitUserOrder: async params =>
			await $post(makeApiUrl(`shop/order/new/`), params),
		getUserOrders: async id =>
			await $post(makeApiUrl(`account/orders/`), { tbl: { pn: 1, ps: 15 } }),

		// user prescription
		getUserPrescriptions: async () =>
			await $get(makeApiUrl(`account/prescription/`)),
		createUserPrescription: async params =>
			await $post(makeApiUrl(`account/prescription/create/`), params),
		editUserPrescription: async (prescriptionId, params) =>
			await $post(
				makeApiUrl(`account/prescription/${prescriptionId}/edit/`),
				params,
			),
		softDeleteUserPrescription: async prescriptionId =>
			await $post(makeApiUrl(`account/prescription/${prescriptionId}/delete/`)),
		// getUserPrescription: async (prescriptionId) => await $get(makeApiUrl(`account/prescription/${prescriptionId}/`)), not used ?

		// user address
		getUserAddresses: async () => await $get(makeApiUrl(`account/address/`)),
		createUserAddress: async params =>
			await $post(makeApiUrl(`account/address/create/`), params),
		editUserAddress: async (addressId, params) =>
			await $post(makeApiUrl(`account/address/${addressId}/edit/`), params),
		deleteUserAddress: async addressId =>
			await $post(makeApiUrl(`account/address/${addressId}/delete/`)),
		updateHomeAddress: async params =>
			await $post(makeApiUrl(`account/update-address/`), params), // not used in BE ?

		// address shipping-restrictions
		getUserShippingRestrictions: async storeId =>
			await $post(makeApiUrl(`${storeId}/user-address/shipping-restrictions/`)),
		getShippingRestrictionCities: async (storeId, params) =>
			await $post(
				makeApiUrl(`stores/${storeId}/shipping-restrictions/`),
				params,
			),
		getUserCitiesAddress: async () =>
			await $get(makeApiUrl(`cities/shipping-restrictions/`)),

		// license
		getUserLicenses: async () => await $get(makeApiUrl(`account/license/`)),
		createUserLicense: async params =>
			await $post(makeApiUrl(`account/license/create/`), params),
		editUserLicense: async (licenseId, params) =>
			await $post(makeApiUrl(`account/license/${licenseId}/edit/`), params),
		softDeleteUserLicense: async licenseId =>
			await $post(makeApiUrl(`account/license/${licenseId}/delete/`)),
		// getUserLicense: async (licenseId) => await $get(makeApiUrl(`account/license/${licenseId}/`)), not used ?

		// strains
		getStrainParents: async id =>
			await $get(makeApiUrl(`common/get-strain-parents/${id}/`)),

		// product notifications
		createProductNotification: async params =>
			await $post(makeApiUrl(`product/create-notification/`), params),
		getProductNotifications: async params =>
			await $post(makeApiUrl(`product/get-notifications/`), params),
		editProductNotification: async (id, params) =>
			await $post(makeApiUrl(`product/edit-notification/${id}/`), params),
		deleteProductNotification: async (id, params) =>
			await $post(makeApiUrl(`product/delete-notification/${id}/`), params),

		// user account
		getUserInfo: async params => await $post(makeApiUrl(`shop/user/`), params),
		updateUserInfo: async params =>
			await $post(makeApiUrl(`shop/user/edit/`), params),
		getStoreOrders: async params =>
			await $post(makeApiUrl(`shop/user/orders/`), params),
		checkAvailability: async params =>
			await $post(makeApiUrl("account/check-availability/"), params),
		requestEmailVerificationAfterChange: async params =>
			await $post(makeApiUrl(`account/change-email/otp-request/`), params),
		emailVerificationAfterChange: async params =>
			await $post(makeApiUrl(`account/change-email/otp-verify/`), params),
		requestPhoneVerificationAfterChange: async params =>
			await $post(makeApiUrl(`account/change-phone/otp-request/`), params),
		phoneVerificationAfterChange: async params =>
			await $post(makeApiUrl(`account/change-phone/otp-verify/`), params),
		requestEmailVerification: async () =>
			await $post(makeApiUrl(`account/verify/email/request/`)),
		submitEmailVerification: async params =>
			await $post(makeApiUrl(`account/verify/email/submit/`), params),
		requestPhoneVerification: async () =>
			await $post(makeApiUrl(`account/verify/phone/request/`)),
		submitPhoneVerification: async params =>
			await $post(makeApiUrl(`account/verify/phone/submit/`), params),
		submitSignup: async params =>
			await $post(makeApiUrl(`account/signup/`), params),
		submitLogout: async () => await $post(makeApiUrl(`account/logout/`)),
		initPasswordRecovery: async params =>
			await $post(makeApiUrl(`account/recovery/init/`), params),
		checkRecoveryToken: async params =>
			await $post(makeApiUrl(`account/recovery/check/`), params),
		submitRecoveryPassword: async params =>
			await $post(makeApiUrl(`account/recovery/submit/`), params),
		changeAccountPassword: async params =>
			await $post(makeApiUrl(`account/change-password/`), params),
		SendConfirmationResetPassword: async params =>
			await $post(makeApiUrl(`account/reset-password/`), params),
		updateAccountInfo: async params =>
			await $post(makeApiUrl(`account/update-info/`), params),
		updateAccountInfoInOrder: async params =>
			await $post(makeApiUrl(`account/update-info/order`), params),

		//
		getMarketer: async (id, params) =>
			await $post(makeApiUrl(`widget/marketer/${id}/`), params),
		getManufacturer: async (id, params) =>
			await $post(makeApiUrl(`widget/manufacturer/${id}/`), params),

		// batches
		getProductBatches: async (id, params) =>
			await $post(makeApiUrl(`batches-by-product/${id}/`), params),
		// reviews
		getReviewQuestions: async id =>
			await $post(
				makeApiUrl(`product-review/get-active-questions-and-options/`),
			),
		createReview: async params =>
			await $post(makeApiUrl(`product-review/create-review/`), params),
		getReviewsByProduct: async id =>
			await $post(makeApiUrl(`product-review/get-reviews-by-product/${id}/`)),
		getReviewsByUser: async id =>
			await $post(makeApiUrl(`product-review/get-reviews-by-user/`)),
		getUserNotReviewedProductsAndBatches: async () =>
			await $post(makeApiUrl(`get-user-not-reviewed-products-and-batches/`)),
		getUserNotReviewedBatches: async id =>
			await $post(makeApiUrl(`get-user-not-reviewed-batches/${id}/`)),
		isVerifiedPurchase: async (id, params) =>
			await $post(
				makeApiUrl(`product-review/is-verified-purchase/${id}/`),
				params,
			),
		getBatchesByOrders: async id =>
			await $post(makeApiUrl(`batches-by-orders/${id}/`)),
		canLeaveReviewForBatch: async (batchId, params) =>
			await $post(makeApiUrl(`can-leave-review-for-batch/${batchId}/`), params),

		getStoresByDistrict: async (id, params) =>
			await $post(makeApiUrl(`stores/district/${id}/`), params),
		getStoreProducts: async (storeId, params) =>
			await $post(makeApiUrl(`common/store-products/${storeId}/`), params),
		validateCartProducts: async params =>
			await $post(makeApiUrl(`common/validate-cart-products/`), params),
		searchInStockProducts: async (query, params) =>
			await $post(makeApiUrl(`widget/products/in-stock/${query}/`), params),
		//* product notifications
		createProductNotification: async params =>
			await $post(makeApiUrl(`product/create-notification/`), params),
		getProductNotifications: async params =>
			await $post(makeApiUrl(`product/get-notifications/`), params),
		editProductNotification: async (id, params) =>
			await $post(makeApiUrl(`product/edit-notification/${id}/`), params),
		deleteProductNotification: async (id, params) =>
			await $post(makeApiUrl(`product/delete-notification/${id}/`), params),
		getSliderMessages: async () => await $get(makeApiUrl(`slider-messages/`)),
		//* strains
		getStrainParents: async id =>
			await $get(makeApiUrl(`common/get-strain-parents/${id}/`)),
		getSimilarProductsByParents: async (productId, storeId) =>
			await $get(
				makeApiUrl(`similar-products-by-parents/${productId}/${storeId}`),
			),
		getShippingRestrictionCities: async (storeId, params) =>
			await $post(
				makeApiUrl(`stores/${storeId}/shipping-restrictions/`),
				params,
			),
		createFileToken: async params =>
			await $post(makeApiUrl(`account/create-url-token/`), params),
		//* user visits
		countUsersPageVisits: async id =>
			await $get(makeApiUrl(`latest-visits/${id}/`)),
		getUserCitiesAddress: async () =>
			await $get(makeApiUrl(`cities/shipping-restrictions/`)),

		// Table endpoint but can give single product by params = {product_id: "id"}
		getWidgetStoreProducts: async (storeId, params) =>
			await $post(makeApiUrl(`widget/products/store/${storeId}/`), params),
		getStoreCityDeliveryInfo: async params =>
			await $post(makeApiUrl(`shop/distance/`), params),
		//
		getSliderMessages: async () => await $get(makeApiUrl(`slider-messages/`)),
		createFileToken: async params =>
			await $post(makeApiUrl(`account/create-url-token/`), params),
		//user visits
		countUsersPageVisits: async id =>
			await $get(makeApiUrl(`latest-visits/${id}/`)),
	};
}
